import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const removeDependency = () => { ajaxService.removeDependency() }

export async function getIndustries(refresh = false) {
    removeDependency()
    if (refresh) {
        return await loadResource(urlService.industries, 'industries');
    } else {
        let industries = await loadState('industries');

        if (industries == null) {
            return await loadResource(urlService.industries, 'industries');
        } else {
            return Response(industries);
        }
    }
}

export async function getIndustry(id) {
    removeDependency()
    let industries = await loadState('industries');
    if (industries !== null) {
        return industries.find(i => i.id == id);
    } else {
        return ajaxService.get(urlService.industries + '/' + id).then(response => {
            return Response(response.data);
        })
    }
}

export async function saveIndustry(data) {
    removeDependency()
    return ajaxService.post(urlService.industries, data).then(response => {
        return Response(response.data);
    })
}

export async function updateIndustry(id, data) {
    removeDependency()
    return ajaxService.put(urlService.industries + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteIndustry(id) {
    removeDependency()
    return ajaxService.delete(urlService.industries + '/' + id).then(response => {
        return Response(response.data);
    })
}