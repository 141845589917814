import React, { memo, useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Avatar, Layout, Menu } from 'antd';
import {
    LoginOutlined,
    ShoppingCartOutlined,
    AppstoreAddOutlined,
    UserOutlined,
    MailOutlined,
    FormOutlined,
    StarOutlined,
    DownCircleOutlined,
    ExclamationCircleOutlined,
    UsergroupAddOutlined,
    TransactionOutlined
} from '@ant-design/icons';
import { FaUtensils } from 'react-icons/fa';

const { Header } = Layout;

const submenuColor = "#173366"

function MainHeader() {

    const location = useLocation();
    let history = useHistory();

    const [storesVisible, setStoresVisible] = useState(false);
    const [newslettersVisible, setNewslettersVisible] = useState(false);
    const [profileVisible, setProfileVisible] = useState(false);
    const [menuItems, setMenuItems] = useState([])

    // const [currentMenu, setCurrentMenu] = useLocalStorageState('menuItem', 'dashboard');
    const [currentMenu, setCurrentMenu] = useState('dashboard');

    const onClick = (key) => {
        if (key === 'logout') {
            logout()
        } else {
            setCurrentMenu(key);
        }
    };

    useEffect(() => {
        const storeItems = ['/stores', '/incomplete-registration'];
        const newsletterItems = ['/newsletters', '/newsletters-subscriber'];
        const profileItems = ['/profile'];

        const isStoreOpen = storeItems.some((item) => location.pathname.startsWith(item));
        const isNewsletterOpen = newsletterItems.some((item) => location.pathname.startsWith(item));
        const isProfileOpen = profileItems.some((item) => location.pathname.startsWith(item));

        setStoresVisible(isStoreOpen);
        setNewslettersVisible(isNewsletterOpen);
        setProfileVisible(isProfileOpen);

        let path = location.pathname;

        if (path === '/') {
            setCurrentMenu('dashboard')
        } else if (isStoreOpen) {
            setCurrentMenu('storesItems')
        } else if (isNewsletterOpen) {
            setCurrentMenu('newslettersItems')
        } else if (isProfileOpen) {
            setCurrentMenu('profileItems')
        } else {

            const menuPath = path.replace(/^\//, '').replace(/-/g, '_');

            const parts = menuPath.split("/");

            setCurrentMenu(parts[0])

        }

    }, [location])

    const logout = () => {
        localStorage.clear();
        history.push({ pathname: '/' })
    }

    const newslettersChilds = (
        [
            {
                label: <p><span><MailOutlined /> </span> Newsletters</p>,
                key: 'newsletters',
                link: '/newsletters',
                onClick: () => history.push({ pathname: '/newsletters' }),
                color: location.pathname.startsWith('/newsletters') ? submenuColor : '',
            },
            {
                label: <p><span><UsergroupAddOutlined /> </span> Subscribers</p>,
                key: 'newsletters-subscriber',
                link: '/newsletters-subscriber',
                onClick: () => history.push({ pathname: '/newsletters-subscriber' }),
                color: location.pathname.startsWith('/newsletters-subscriber') ? submenuColor : '',
            },
        ]
    )

    const profileChilds = (
        [
            {
                label: <p><span><UserOutlined /> </span> Profile</p>,
                key: 'profile',
                link: '/profile',
                onClick: () => history.push({ pathname: '/profile' }),
                color: location.pathname.startsWith('/profile') ? submenuColor : '',
            },
            {
                label: "Logout",
                key: 'logout',
                icon: <LoginOutlined />,
            },
        ]
    )

    const storesChilds = (
        [
            {
                label: <p><span><ShoppingCartOutlined /> </span> Stores</p>,
                key: 'storesChilds',
                link: '/stores',
                onClick: () => history.push({ pathname: '/stores' }),
                color: location.pathname.startsWith('/stores') ? submenuColor : ''
            },
            {
                label: <p><span><ExclamationCircleOutlined /> </span> Pending</p>,
                key: 'incomplete-registration',
                link: '/incomplete-registration',
                onClick: () => history.push({ pathname: '/incomplete-registration' }),
                color: location.pathname.startsWith('/incomplete-registration') ? submenuColor : '',
            },
        ]
    )

    useEffect(() => {

        let items = [
            {
                label: "Dashboard",
                key: 'dashboard',
                onClick: () => history.push({ pathname: '/' }),
                icon: <AppstoreAddOutlined />,
                visible: true
            },
            {
                label: 'Stores',
                key: 'stores',
                icon: <DownCircleOutlined />,
                children: storesVisible ? '' : storesChilds,
                visible: true
            },
            {
                label: "Features",
                key: 'features',
                onClick: () => history.push({ pathname: '/features' }),
                icon: <StarOutlined />,
                visible: false
            },
            {
                label: "Permissions",
                key: 'permissions',
                onClick: () => history.push({ pathname: '/permissions' }),
                icon: <StarOutlined />,
                visible: false
            },
            {
                label: "Cuisines",
                key: 'cuisines',
                onClick: () => history.push({ pathname: '/cuisines' }),
                icon: <FaUtensils />,
                visible: true
            },
            {
                label: "Online Payment Transaction",
                key: 'online_payment_transactions',
                onClick: () => history.push({ pathname: '/transactions' }),
                icon: <TransactionOutlined />,
                visible: true
            },
            {
                label: "Users",
                key: 'users',
                onClick: () => history.push({ pathname: '/users' }),
                visible: true
            },
            {
                label: <Avatar icon={<UserOutlined />} />,
                key: 'profileItems',
                children: profileVisible ? '' : profileChilds,
                visible: true

            },
        ];

        let user = JSON.parse(localStorage.getItem('user'));

        if (user.is_developer) {

            items = items.map(item => {
                if (item.key === 'permissions') {
                    item.visible = true;
                }

                return item;
            })
        }

        if (user.is_sub_admin) {

            let perms = JSON.parse(localStorage.getItem('perms'));

            items = items.map(item => {
                if (perms.hasOwnProperty(item.key)) {
                    return { ...item, visible: perms[item.key] };
                } else {
                    return item;
                }
            });            
        }

        setMenuItems(items.filter(i => i.visible))

    }, [])


    const subItems = () => {
        if (storesVisible) return storesChilds;
        if (profileVisible) return profileChilds;
        if (newslettersVisible) return newslettersChilds;
        return [''];
    }

    const subMenuOnclick = (item) => {
        if (item.key == 'logout') {
            logout()
        } else {
            history.push({ pathname: item.link })
        }
    }

    function renderMenuItems(items) {
        return items
            .filter(Boolean) // Remove null values from the array
            .map((item) => (
                <li key={item.key} className="menu-item">
                    <a onClick={() => subMenuOnclick(item)} style={{ color: item.color }}>{item.label}</a>
                </li >
            ));
    }

    return (
        <>
            <Header>
                <Menu
                    mode="horizontal"
                    items={menuItems}
                    onClick={(e) => onClick(e.key)}
                    selectedKeys={[currentMenu]}
                    style={{ background: '#003366', color: '#FFF' }}
                />
            </Header>

            {/* Sub Menu */}
            <ul className="custom-sub-menu" >
                {renderMenuItems(subItems())}
            </ul>

        </>
    )
}

export default memo(MainHeader);