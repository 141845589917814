import { Form, Input, Button, notification, Row, Col, Space, Select, Switch } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CheckCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ImageUpload from '../../components/image-upload';
import { getIndustries, getStore, saveStore, updateStore } from '../../data';
import windowSize from 'react-window-size';
import { ajaxService } from '../../services';
import { GlobalVariable } from '../../services/ajax-service';

const { Option } = Select
function StoreForm({ windowWidth }) {

    const [fileList, setFileList] = useState([]);
    const [fileList2, setFileList2] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [forceRefresh, setForceRefresh] = useState(true);

    const [form] = Form.useForm();
    const isMobile = windowWidth <= 768;

    let history = useHistory();
    let { id } = useParams();

    const onChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setFileList(fileList);
    };

    const handleCoverImage = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setFileList2(fileList);
    };


    const onFinish = async (values) => {

        let link = fileList.length > 0 ? fileList[0].response?.success ? fileList[0].response.path : fileList[0].url?.replace(GlobalVariable.BASE_IMAGE_URL + '/', '') : null;
        let coverImage = fileList2.length > 0 ? fileList2[0].response?.success ? fileList2[0].response.path : fileList2[0].url?.replace(GlobalVariable.BASE_IMAGE_URL + '/', '') : null;

        delete values.file;
        let data = {
            name: values.name,
            domain: values.domain,
            logo: link,
            cover_image: coverImage,
            description: values.description,
            slogan: values.slogan,
            industry: values.industry,
            active: values.active
        }

        const updateData = await updateStore(id, data);
        if (updateData) {
            history.push({ pathname: "/stores/view/" + id });
            notification.open({ message: 'Store has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to update store', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

        }


    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadIndutries = async () => {
        const response = await getIndustries(forceRefresh);
        if (response) {
            setIndustries(response)
            setForceRefresh(false)
        }
    }

    const loadData = async (id) => {
        if (id > 0) {
            const data = await getStore(id)
            if (data != null) {
                form.setFieldsValue({ ...data });

                const { logo, cover_image } = data

                if (logo) {
                    let imageItem = {
                        uid: logo,
                        name: 'image.png',
                        status: 'done',
                        url: ajaxService.getImage(logo),
                        preview: false
                    }
                    setFileList([imageItem]);
                }

                if (cover_image) {
                    let imageItem = {
                        uid: cover_image,
                        name: 'image.png',
                        status: 'done',
                        url: ajaxService.getImage(cover_image),
                        preview: false
                    }
                    setFileList2([imageItem]);
                }
            }
        }
    }

    useEffect(() => {
        loadData(id)
        loadIndutries()
    }, [])


    const renderStoreForm = () => {
        return (
            <>
                <Form
                    layout={'vertical'}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                >
                    <Row gutter={16} >
                        <Col span={6}></Col>
                        <Col span={12} >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Store Name" name='name' rules={[
                                        {
                                            required: true,
                                            message: 'Store Name is required',
                                        },
                                    ]}>
                                        <Input placeholder="Store name" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='slogan' label="Store Slogan" rules={[
                                        {
                                            required: true,
                                            message: 'Slogan is required',
                                        },
                                    ]}>
                                        <Input placeholder="Store slogan" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item name='domain' label="Store Website" rules={[
                                        {
                                            required: true,
                                            message: 'Store website is required',
                                        },
                                    ]}>
                                        <Input placeholder="Store website" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='description' label="Store Description" rules={[
                                        {
                                            required: true,
                                            message: 'Store description is required',
                                        },
                                    ]}>
                                        <Input placeholder="Store description" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='industry_id' label="Store Industry" rules={[
                                        {
                                            required: true,
                                            message: 'Store industry is required',
                                        },
                                    ]}>
                                        <Select placeholder="Store industry">
                                            {industries.map(({ id, name }, i) => (
                                                <Option key={i} value={id}>{name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="active" label="Approve" valuePropName="checked">
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            defaultChecked
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row >
                                <Col span={12}>
                                    <Form.Item label="Upload Logo" name="file" rules={[
                                        {
                                            required: false,
                                            message: 'Store logo is required',
                                        },
                                    ]}>
                                        <ImageUpload onChange={onChange} fileList={fileList} crop={true} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="Cover Image" name="cover_image" >
                                        <ImageUpload onChange={handleCoverImage} fileList={fileList2} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Item>
                                    <Space>
                                        <Button htmlType="submit" type="primary">Submit</Button>
                                        <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                    </Space>
                                </Form.Item>
                            </Row>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </>
        );
    }

    const renderStoreFormMobile = () => {

        return (
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}>
                <Row gutter={16} >
                    <Col span={24} >
                        <Form.Item label="Store Name" name='name' rules={[
                            {
                                required: true,
                                message: 'Store Name is required',
                            },
                        ]}>
                            <Input placeholder="Store name" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='slogan' label="Store Slogan" rules={[
                            {
                                required: true,
                                message: 'Slogan is required',
                            },
                        ]}>
                            <Input placeholder="Store slogan" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='domain' label="Store Website" rules={[
                            {
                                required: true,
                                message: 'Store website is required',
                            },
                        ]}>
                            <Input placeholder="Store website" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='description' label="Store Description" rules={[
                            {
                                required: true,
                                message: 'Store description is required',
                            },
                        ]}>
                            <Input placeholder="Store description" />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Upload Logo" name="file" rules={[
                            {
                                required: false,
                                message: 'Store logo is required',
                            },
                        ]}>
                            <ImageUpload onChange={onChange} fileList={fileList} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item>
                            <Space>
                                <Button htmlType="submit" type="primary">Submit</Button>
                                <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderStoreFormMobile()
        } else {
            return renderStoreForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )


};
export default windowSize(StoreForm)