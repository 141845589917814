import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Input, Row, Space, Form, notification, Switch } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getPermission, savePermission, updatePermission } from "../../data";

function PermissionForm() {

    const [form] = Form.useForm();
    let history = useHistory();
    let { id } = useParams();

    const onFinish = async (values) => {

        let data = {
            title: values.title,
            name: values.name,
            is_default: values.is_default || false,
        }

        if (id == 0) {
            const saveData = await savePermission(data)
            if (saveData) {
                history.push({ pathname: "/permissions" });
                notification.open({ message: 'Permission has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add permission', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
        else {
            const updateData = await updatePermission(id, data);
            if (updateData) {
                history.push({ pathname: '/permissions' });
                notification.open({ message: 'Permission has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update permission', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };


    useEffect(() => {

        const loadData = async (id) => {
            if (id > 0) {
                let data = await getPermission(id);
                if (data) {
                    form.setFieldsValue({ ...data });
                }
            }
        }

        loadData(id)

    }, [])

    return (
        <>
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}>
                <Row gutter={16} >
                    <Col span={6}></Col>
                    <Col span={12} >
                        <Row gutter={16}>

                            <Col span={12}>
                                <Form.Item label="Title" name='title' rules={[
                                    {
                                        required: true,
                                        message: 'Title is required',
                                    }]}
                                >
                                    <Input placeholder="Enter Title" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Slug" name='name' rules={[
                                    {
                                        required: true,
                                        message: 'Lower case slug is required',
                                    }]}
                                >
                                    <Input placeholder="Enter slug" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Mark as Default" name='is_default' valuePropName="checked" >
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}

                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col span={6}></Col>
                </Row >
            </Form >
        </>
    )
}

export default PermissionForm