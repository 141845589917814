import {  Button, Modal, notification, Space, Table, Typography, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { CloseCircleOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { deletePermission, getPermissions } from "../../data";

const { Title } = Typography;

export default function Permissions() {

    let history = useHistory()
    const [dataSource, setDataSource] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(true);

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Name / Slug',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Enabled by default',
            dataIndex: 'is_default',
            key: 'is_default',
            render: (is_default) => <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={is_default}
            />
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <Space size='small'>
                        <Button onClick={() => history.push({ pathname: '/permissions/form/' + record.id })}>Edit</Button>
                        <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
                    </Space>)
            }
        },
    ]

    const deleteRow = async () => {
        let permission_id = localStorage.getItem('permission')
        const data = await deletePermission(permission_id);

        if (data) {
            setShowModal(false)
            setForceRefresh(true)
            notification.open({ message: 'Permission has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to deleted permission', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('permission', id);
        setShowModal(true)
    }

    const initialize = async () => {
        const data = await getPermissions(forceRefresh);
        if (data) {
            setDataSource(data);
            setForceRefresh(false);
        }
    }

    useEffect(() => {
        if (forceRefresh) {
            initialize();
        }
    }, [forceRefresh])

    const renderHeader = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Permissions</Title>
            <Space size="small" >
                <Button type="primary" onClick={() => history.push({ pathname: '/permissions/form/0' })}>Add Permission</Button>
            </Space>
        </div>
    )

    return (
        <>
            <Table
                dataSource={dataSource}
                columns={columns}
                title={() => renderHeader()}
                pagination={false}
                rowKey={'id'}
            />
            <Modal
                title="Delete Permission"
                centered
                open={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button key="delete" onClick={() => deleteRow()}>
                        Delete
                    </Button>,
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                ]}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Are you sure you want to delete this permission?</p>
                </div>
            </Modal>
        </>
    )
}  