import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const removeDependency = () => { ajaxService.removeDependency() }

export async function getPermissions(refresh = false) {
    removeDependency()
    if (refresh) {
        return await loadResource(urlService.permissions, 'permissions');
    } else {
        let permissions = await loadState('permissions');

        if (permissions == null) {
            return await loadResource(urlService.permissions, 'permissions');
        } else {
            return Response(permissions);
        }
    }
}

export async function getPermission(id) {
    removeDependency()
    // let permissions = await loadState('permissions');
    // if (permissions !== null) {
    //     return permissions.find(i => i.id === id);
    // } else {
    // }
    return ajaxService.get(urlService.permissions + '/' + id).then(response => {
        return Response(response.data);
    })
}


export async function savePermission(data) {
    removeDependency()
    return ajaxService.post(urlService.permissions, data).then(response => {
        return Response(response.data);
    })
}

export async function updatePermission(id, data) {
    removeDependency()
    return ajaxService.put(urlService.permissions + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deletePermission(id) {
    removeDependency()
    return ajaxService.delete(urlService.permissions + '/' + id).then(response => {
        return Response(response.data);
    })
}