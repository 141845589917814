import { ajaxService, urlService } from "../services";
import { Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getUsers(refresh = false) {
    return ajaxService.get(urlService.users).then(response => {
        return Response(response.data);
    })
}

export async function getUser(id) {
    return ajaxService.get(urlService.users + '/' + id ).then(response => {
        return Response(response.data);
    })
}

export async function saveUser(data) {
    setDependency()
    return ajaxService.post(urlService.users, data).then(response => {
        return Response(response.data);
    })
}

export async function updateUser(id, data) {
    return ajaxService.put(urlService.users + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteUser(id) {
    return ajaxService.delete(urlService.users + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function filterUser(data) {
    return await ajaxService.post(urlService.userFilter, data).then(response => {
        return Response(response.data);
    });
}

export async function authUser() {
    ajaxService.removeDependency()
    return ajaxService.get(urlService.user).then(response => {
        return response.data;
    })
}

