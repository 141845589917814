import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Modal, notification, Typography, Row, Col, Form, Input, Switch, Card } from 'antd';
import { useHistory } from "react-router-dom";
import { EditOutlined, CloseCircleOutlined, DeleteOutlined, CheckOutlined, CloseOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { deleteUser, filterUser, getUsers, updateUser } from '../../data';
import windowSize from 'react-window-size';
import { PAGINATION_OPTIONS } from '../../constants';


const { Title, Text } = Typography;

const Users = ({ windowWidth }) => {
  const [dataSource, setDataSource] = useState([]);
  const [storeRestriction, setStoreRestriction] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(true);
  const isMobile = windowWidth <= 768
  let history = useHistory();
  const viewRecords = true;
  const addRecord = true;
  const editRecord = true;
  const deleteRecord = true;

  const updateValue = async (id, checked, type, name) => {

    const updatedUsers = dataSource.map(item => {
      if (item.id === id) {
        return { ...item, verified: checked };
      }
      return item;
    });
    setDataSource(updatedUsers);

    const emailVerified = await updateUser(id, { type: type, value: checked })
    if (emailVerified) {
      setForceRefresh(true)
      if (checked === true) {
        notification.open({ message: name + ' email has been verified', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
      } else if (checked === false) {
        notification.open({ message: name + ' email has been disaproved', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
      }
    }
    else {
      notification.open({ message: 'Unable to update email verified', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'Name',
      responsive: ["sm"],
      render: (name, { primary, auth }) => (
        <Text>
          {name}
          <span style={{ color: '#1E90FF ' }}>{primary ? ' (Primary)' : ''}</span>
          <span style={{ color: "#FF5733" }}>{auth ? ' (You)' : ''}</span>
        </Text>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      responsive: ["sm"]

    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (text, { primary, auth, id }) => (
        <Space size='small'>
          {
            (!primary && !auth) &&
            <>
              <Button onClick={() => history.push({ pathname: "/users/form/" + id })}>Edit</Button>
              {/* <Button onClick={() => openDeleteModal(id)}>Delete</Button> */}
            </>
          }
        </Space >
      ),
    },
  ];

  const search = async () => {
    var formData = new FormData();
    if (keyword !== '') {
      formData.append('name', keyword);
    }
    const data = await filterUser(formData);
    if (data) {
      setDataSource(data);
      if (data.length == 0) {
        notification.open({ message: keyword + ' not found', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
      }
    } else {
      notification.open({ message: 'User not found', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }
  }

  const deleteRow = async () => {
    let user_id = localStorage.getItem('user')
    const data = await deleteUser(user_id);
    if (data) {
      setShowModal(false)
      setForceRefresh(true)
      notification.open({ message: 'User has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
    } else {
      setShowModal(false)
      notification.open({ message: 'Unable to delete user', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }
  }

  const openDeleteModal = (id) => {
    localStorage.setItem('user', id);
    setShowModal(true)
  }

  const initialize = async () => {
    const response = await getUsers();

    if (response) {
      const store = response[0].user_store;

      if (store && store.store_restrictions) {
        setStoreRestriction(store.store_restrictions);
      } else {
        setStoreRestriction([]);
      }

      setDataSource(response);
      setForceRefresh(false);
    }
  }

  useEffect(() => {
    if (forceRefresh) {
      initialize();
      setForceRefresh(false)
    }
  }, [forceRefresh])

  const renderSearchBox = () => (
    <Form>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Search">
            <Input placeholder='Search User' value={keyword} onChange={(e) => setKeyword(e.target.value)} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item>
            <Space size='small'>
              <Button type="primary" onClick={() => search()}  >
                Search
              </Button>
              <Button type="primary" onClick={() => setKeyword('')}  >
                Clear
              </Button>
            </Space>
          </Form.Item>
        </Col>
        <Col span={12}>
        </Col>
      </Row>
    </Form>
  )

  const onClick = () => {

    history.push({ pathname: "/users/form/0" })


  }

  const renderHeader = () => (
    <div className='flex'>
      <Title level={3}>Users</Title>
      {addRecord && <Button type="primary" onClick={onClick}>Add User</Button>}
    </div>
  );

  const renderModal = () => (
    <Modal
      title="Delete User"
      centered
      visible={showModal}
      onCancel={() => setShowModal(false)}
      footer={[
        <Button key="delete" onClick={() => deleteRow()}>
          Delete
        </Button>
        ,
        <Button key="back" onClick={() => setShowModal(false)}>
          Close
        </Button>
      ]}
    >
      <p>Are you sure you want to delete this user?</p>
    </Modal>
  )

  const renderUsers = () => (
    <>
      <Form layout={'vertical'}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Search">
              <Input placeholder='Search User' value={keyword} onChange={(e) => setKeyword(e.target.value)} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label=" ">
              <Space size='small'>
                <Button type="primary" onClick={() => search()}  >
                  Search
                </Button>
                <Button type="primary" onClick={() => setKeyword('')}  >
                  Clear
                </Button>
              </Space>
            </Form.Item>
          </Col>
          <Col span={12}>
          </Col>
        </Row>
      </Form>
      <Table
        dataSource={dataSource}
        columns={columns}
        title={() => renderHeader()}
        rowKey="id"
        pagination={{ ...PAGINATION_OPTIONS }}
      />
    </>
  )

  const renderUsersMobile = () => (
    <>
      {renderSearchBox()}
      {
        dataSource.map(user => {
          return (
            <>
              <Card
                style={{ width: '100%' }}
                actions={[
                  <Space size='small' >
                    {editRecord && <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: "/users/form/" + user.id })}  >Edit</Button>}
                    {deleteRecord && <Button type='primary' danger icon={<DeleteOutlined />} onClick={() => openDeleteModal(user.id)}>Delete</Button>}
                  </Space>
                ]}>
                <Text style={{ fontWeight: 'bolder' }}>Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{user.name}</span></Text>
                <br />
                <Text style={{ fontWeight: 'bolder' }}>Email :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{user.email}</span></Text>
                <br />
                <Text style={{ fontWeight: 'bolder' }}>Role :
                  <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{user.roles.length > 0 ? user.roles[0].title : ''}</span>
                </Text>
                <br />
                <Text style={{ fontWeight: 'bolder' }}>Email Verified :
                  <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                    <Switch
                      onChange={(val) => updateValue(user.id, val, 'verified', user.name)}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      checked={user.verified == 1 ? true : false} />
                  </span>
                </Text>
                <br />
              </Card>
              <br />
            </>
          )
        })}
    </>
  )

  const renderContetnt = () => {
    if (isMobile) {
      return renderUsersMobile()
    }
    else {
      return renderUsers()
    }
  }

  return (
    <>
      {isMobile && renderHeader()}
      {renderContetnt()}
      {renderModal()}
    </>
  )

}

export default windowSize(Users)