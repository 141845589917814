import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Card, Checkbox, Col, Divider, Form, Input, Row, Typography, notification } from "antd"
import { CheckCircleOutlined, CloseCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { getStore, updateStoreLimits } from "../../data";
import Limits from "../../constants/store_limits";

const { Title } = Typography
const StoreLimits = () => {


    let { id } = useParams()
    let history = useHistory()
    const [form] = Form.useForm()

    const [posUnlimited, setPosUnlimites] = useState(false)
    const [ecommerceUnlimited, setEcommerceUnlimites] = useState(false)
    const [branchUnlimited, setBranchUnlimites] = useState(false)
    const [userUnlimited, setUserUnlimites] = useState(false)

    const onFinish = async (values) => {

        const data = [
            { type: Limits.POS_ORDER_LIMITS, values: posUnlimited ? 0 : values.pos_order_limits },
            { type: Limits.ECOMMERCE_ORDER_LIMITS, values: ecommerceUnlimited ? 0 : values.ecommerce_order_limits },
            { type: Limits.BRANCH_LIMITS, values: branchUnlimited ? 0 : values.branch_limits },
            { type: Limits.USER_LIMITS, values: userUnlimited ? 0 : values.user_limits }
        ]

        const response = await updateStoreLimits(id, data);
        if (response) {
            history.push({ pathname: '/stores/view/' + id });
            notification.open({ message: 'Store limits has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to update storelimits', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const loadStoreLimits = async () => {
        const response = await getStore(id)

        if (response) {
            const limits = response.store_restrictions;

            if (Object.values(limits).length == 0) {
                setPosUnlimites(true)
                setEcommerceUnlimites(true)
                setBranchUnlimites(true)
                setUserUnlimites(true)

                form.setFieldsValue({
                    pos_order_limits: 0,
                    ecommerce_order_limits: 0,
                    branch_limits: 0,
                    user_limits: 0,
                })
            } else {
                limits.map(i => {

                    if (Limits.POS_ORDER_LIMITS == i.type) {
                        setPosUnlimites(i.value == 0 ? true : false)
                        form.setFieldsValue({ pos_order_limits: i.value })
                    }

                    if (Limits.ECOMMERCE_ORDER_LIMITS == i.type) {
                        setEcommerceUnlimites(i.value == 0 ? true : false)
                        form.setFieldsValue({ ecommerce_order_limits: i.value })
                    }

                    if (Limits.BRANCH_LIMITS == i.type) {
                        setBranchUnlimites(i.value == 0 ? true : false)
                        form.setFieldsValue({ branch_limits: i.value })
                    }

                    if (Limits.USER_LIMITS == i.type) {
                        setUserUnlimites(i.value == 0 ? true : false)
                        form.setFieldsValue({ user_limits: i.value })
                    }

                })
            }

        }
    }

    useEffect(() => {
        loadStoreLimits()
    }, [])

    const renderHeader = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Store Limits</Title>
            <Button type="primary" onClick={() => history.goBack()} icon={<ArrowLeftOutlined />}>Back</Button>
        </div>
    )

    return (
        <Card>
            {renderHeader}
            <Form form={form} onFinish={onFinish}>
                <Divider plain>POS Order Limits</Divider>
                <Row gutter={16}>
                    <Col span={8}></Col>
                    <Col span={4}>
                        <Form.Item name="pos_order_limits"
                            rules={[
                                {
                                    required: posUnlimited ? false : true,
                                    message: 'Please enter POS order limits'
                                }
                            ]}
                        >
                            <Input type="number" placeholder="Enter POS Order Limits" disabled={posUnlimited} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Checkbox checked={posUnlimited} onChange={(e) => setPosUnlimites(e.target.checked)}>Unlimited</Checkbox>
                    </Col>
                    <Col span={6}></Col>
                </Row>

                <Divider plain>Ecommerce Order Limits</Divider>
                <Row gutter={16}>
                    <Col span={8}></Col>
                    <Col span={4}>
                        <Form.Item name="ecommerce_order_limits"
                            rules={[
                                {
                                    required: ecommerceUnlimited ? false : true,
                                    message: 'Please enter ecommerce order limits'
                                }
                            ]}>
                            <Input type="number" placeholder="Enter Ecommerce Order Limits" disabled={ecommerceUnlimited} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Checkbox checked={ecommerceUnlimited} onChange={(e) => setEcommerceUnlimites(e.target.checked)}>Unlimited</Checkbox>
                    </Col>
                    <Col span={6}></Col>
                </Row>

                <Divider plain>Branch Limits</Divider>
                <Row gutter={16}>
                    <Col span={8}></Col>
                    <Col span={4}>
                        <Form.Item name="branch_limits"
                            rules={[
                                {
                                    required: branchUnlimited ? false : true, message: 'Please enter branch limits'
                                }
                            ]}
                        >
                            <Input type="number" placeholder="Enter Branch Limits" disabled={branchUnlimited} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Checkbox checked={branchUnlimited} onChange={(e) => setBranchUnlimites(e.target.checked)}>Unlimited</Checkbox>
                    </Col>
                    <Col span={6}></Col>
                </Row>

                <Divider plain>Users Limits</Divider>
                <Row gutter={16}>
                    <Col span={8}></Col>
                    <Col span={4}>
                        <Form.Item name="user_limits" rules={[
                            {
                                required: userUnlimited ? false : true, message: 'Please enter user limits'
                            }
                        ]}
                        >
                            <Input type="number" placeholder="Enter User Limits" disabled={userUnlimited} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Checkbox checked={userUnlimited} onChange={(e) => setUserUnlimites(e.target.checked)}>Unlimited</Checkbox>
                    </Col>
                    <Col span={6}></Col>
                </Row>

                <center>
                    <Button htmlType="submit" type="primary">Submit</Button>
                </center>
            </Form>
        </Card>
    )
}

export default StoreLimits