
import { useEffect, useState } from "react"
import { CheckOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Table, Switch, Typography, notification, Popconfirm } from "antd"
import { PAGINATION_OPTIONS } from "../../constants";
import { getIncompleteRegistration, updateIncompleteRegistration } from "../../data";
import moment from "moment";

const { Title, Text } = Typography

const IncompleteRegistration = () => {

    const [verified, setVerified] = useState(false);
    const [forceRefresh, setForceRefresh] = useState(true);

    const [dataSource, setDataSource] = useState([])

    const updateValue = async (id, checked, type) => {
        const active = await updateIncompleteRegistration(id, { type: type, value: checked })
        setForceRefresh(true)
        if (active) {
            if (checked === true) {
                notification.open({ message: 'User verified', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else if (checked === false) {
                notification.open({ message: 'User unverified', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            }
        }
        else {
            notification.open({ message: 'Unable to update active', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const handleToggle = (id, checked) => {
        const updatedUser = dataSource.map(v => {
            if (v.id === id) {
                return { ...v, verified: checked };
            }
            return v;
        });
        setDataSource(updatedUser);
        setVerified(checked)
        // initialize()
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Email Verified',
            dataIndex: 'email_verified_at',
            key: 'email_verified_at',
            render: (email_verified_at) => <Text>{email_verified_at ? moment(email_verified_at).format('hh:mm a D-MMM-Y') : "None"}</Text>,
        },
        {
            title: 'Approve',
            dataIndex: 'verified',
            key: 'verified',
            render: (text, record) => {
                return (

                    <Popconfirm
                        title="Are you sure to verify or unverify this user"
                        onConfirm={() => updateValue(record.id, verified, 'verified')}
                        onCancel={() => setForceRefresh(true)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Switch
                            checked={record.verified}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            onChange={(e) => handleToggle(record.id, e)}
                        />
                    </Popconfirm>
                )
            }
        },
    ]

    const initialize = async () => {
        const data = await getIncompleteRegistration();
        if (data) {
            let rows = data.filter(s => !s.verified);
            setDataSource(rows);
        }
    }

    useEffect(() => {
        if (forceRefresh) {
            initialize();
            setForceRefresh(false)
        }
    }, [forceRefresh])

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            title={() => {
                return (
                    <>
                        <Title level={3}>Incomplete Registration</Title>
                        <Text>List of all users who do not have stores.</Text>
                    </>
                )
            }}
            rowKey="id"
            pagination={{ ...PAGINATION_OPTIONS }}
        />
    )
}

export default IncompleteRegistration