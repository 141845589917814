import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const removeDependency = () => ajaxService.removeDependency()
const setDependency = () => ajaxService.setDependency()

export async function getStores(refresh = false, $params = null) {
    removeDependency()
    if (refresh) {
        return await loadResource(`${urlService.stores}${$params}`, 'stores');
    } else {
        let stores = await loadState('stores');

        if (stores == null) {
            return await loadResource(urlService.stores, 'stores');
        } else {
            return Response(stores)
        }
    }
}

export async function getStore(id) {
    removeDependency()
    return ajaxService.get(urlService.stores + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function saveStore(data) {
    removeDependency()
    return ajaxService.post(urlService.stores, data).then(response => {
        return Response(response.data);
    })
}

export async function updateStore(id, data) {
    removeDependency()
    return ajaxService.put(urlService.stores + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function saveStorePermissions(id, data) {
    removeDependency()
    return ajaxService.post(urlService.storePermissions + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function getStorePermissions(id) {
    removeDependency()
    return ajaxService.get(urlService.storePermissions + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function assignSetting(id, data) {
    removeDependency()
    return ajaxService.put(urlService.assignSettingsToStore + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function updateOwner(id, data) {
    setDependency()
    return ajaxService.put(urlService.users + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function getOwner(id) {
    setDependency()
    return ajaxService.get(urlService.users + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function updateStoreLimits(id, data) {
    removeDependency()
    return ajaxService.put(urlService.storeLimits + '/' + id, data).then(response => {
        return Response(response.data);
    })
}