import { ajaxService, urlService } from "../services";
import { Response } from "../utility";

const removeDependency = () => { ajaxService.removeDependency() }

export async function getDashboard() {
    removeDependency()
    return ajaxService.get(urlService.dashboard).then(response => {
        return Response(response.data);
    })
}

export async function getChartFilter(data) {
    removeDependency()
    return ajaxService.post(urlService.chartFilter, data).then(response => {
        return Response(response.data);
    })
}

export async function getIncompleteRegistration() {
    removeDependency()
    return ajaxService.get(urlService.incompleteRegistration).then(response => {
        return Response(response.data);
    })
}

export async function updateIncompleteRegistration(id, data) {
    removeDependency()
    return ajaxService.put(urlService.incompleteRegistration + '/' + id, data).then(response => {
        return Response(response.data);
    })
}