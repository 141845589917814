import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import { getStore, getStoreSettings, getStoreSettingsGlobal } from "../../data"
import { Switch, Typography, Button, notification, Table } from "antd"
import { CheckOutlined, CloseOutlined, ArrowLeftOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { assignSetting } from "../../data/store-service";

const { Title } = Typography

function StoreFeatures() {
    let { id: store_id } = useParams()
    let history = useHistory()

    const [store, setStore] = useState({})
    const [forceRefresh, setForceRefresh] = useState(true)

    const [storeSettingsGlobal, setStoreSettingsGlobal] = useState([])

    const handleAssign = async (id, checked, slug) => {

        const data = { store_id: store_id, checked: checked, slug: slug };

        const response = await assignSetting(id, data)

        if (response) {
            notification.open({ message: 'The feature has been ' + (checked ? 'assigned' : 'unassigned'), icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to assigned feature', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const loadStore = async (id) => {
        const response = await getStore(id)
        if (response) {
            setStore(response)
        }
    }

    const initialize = async (id) => {
        const response = await getStoreSettings(id)
        console.log(response)
        if (response) {
            setStoreSettingsGlobal(response)
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        initialize(store_id)
        loadStore(store_id)
    }, [])

    const renderHeader = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Features For {store.name}</Title>
            <Button type="primary" onClick={() => history.push({ pathname: '/stores/view/' + store_id })} icon={<ArrowLeftOutlined />}>Back To Store Detail</Button>
        </div>
    )

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Assign Feature',
            dataIndex: 'active',
            key: 'active',
            render: (active, record) => (
                <Switch
                    onChange={(checked) => handleAssign(record.id, checked, record.slug)}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    // checked={action ? true : false}
                    defaultChecked={active}
                />
            )
        },
    ];

    return (
        <>
            <Table
                title={() => renderHeader}
                columns={columns}
                dataSource={storeSettingsGlobal}
                pagination={false}
                rowKey="id"
            />
        </>
    )

}

export default StoreFeatures