import { Avatar, Button, Modal, notification, Space, Switch, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { CloseCircleOutlined, DeleteOutlined, CheckOutlined, CloseOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { PAGINATION_OPTIONS } from "../../constants";
import { deleteIndustry, getIndustries, updateIndustry } from "../../data";
import { ajaxService } from "../../services";
import { updateDataSource } from "../../utility/updateDataSource";

const { Title } = Typography;

export default function Industries() {

    let history = useHistory()
    const [dataSource, setDataSource] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(true);

    const updateValue = async (id, checked, type) => {

        const updatedCuisines = updateDataSource(dataSource, id, checked, type);
        setDataSource(updatedCuisines);

        const active = await updateIndustry(id, { type: type, value: checked })
        if (active) {
            setForceRefresh(true)
            notification.open({
                message: 'This industry has been ' + (checked ? 'assigned ' : 'unassigned ') + (type == 'generic_name' ? 'generic name' : type == 'expiry_date' ? 'expiry date' : type),
                icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />
            })
        }
        else {
            notification.open({ message: 'Unable to update', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const toggle = (id, action, type) => {
        return <Switch
            onChange={(checked) => updateValue(id, checked, type)}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={action ? true : false}
        // defaultChecked={action ? true : false}
        />
    }

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (image) => <Avatar shape="square" size={64} src={ajaxService.getImage(image)} />
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Addon',
            dataIndex: 'addon',
            key: 'addon',
            render: (addon, record) => toggle(record.id, addon, 'addon')
        },
        {
            title: 'Generic Name',
            dataIndex: 'generic_name',
            key: 'generic_name',
            width: "11%",
            render: (generic_name, record) => toggle(record.id, generic_name, 'generic_name')
        },
        {
            title: 'Combo',
            dataIndex: 'combo',
            key: 'combo',
            render: (combo, record) => toggle(record.id, combo, 'combo')
        },
        {
            title: 'Expiry Date',
            dataIndex: 'expiry_date',
            key: 'expiry_date',
            width: "11%",
            render: (expiry_date, record) => toggle(record.id, expiry_date, 'expiry_date')
        },
        {
            title: 'Cuisine',
            dataIndex: 'cuisine',
            key: 'cuisine',
            render: (cuisine, record) => toggle(record.id, cuisine, 'cuisine')
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <Space size='small'>
                        <Button onClick={() => history.push({ pathname: '/industries/form/' + record.id })}>Edit</Button>
                        {/* <Button onClick={() => openDeleteModal(record.id)}>Delete</Button> */}
                    </Space>)
            }
        },
    ]

    const deleteRow = async () => {
        let industry_id = localStorage.getItem('industry')
        const data = await deleteIndustry(industry_id);

        if (data) {
            setShowModal(false)
            setForceRefresh(true)
            notification.open({ message: 'Industry has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to deleted industry', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('industry', id);
        setShowModal(true)
    }

    const initialize = async () => {
        const data = await getIndustries(forceRefresh);
        if (data) {
            setDataSource(data);
            setForceRefresh(false);
        }
    }

    useEffect(() => {
        if (forceRefresh) {
            initialize();
        }
    }, [forceRefresh])

    const renderHeader = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Industries</Title>
            {/* <Space size="small" >
                <Button type="primary" onClick={() => history.push({ pathname: '/industries/form/0' })}>Add Industry</Button>
            </Space> */}
        </div>
    )

    return (
        <>
            <Table
                dataSource={dataSource}
                columns={columns}
                title={() => renderHeader()}
                pagination={false}

            />
            <Modal
                title="Delete Industry"
                centered
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button key="delete" onClick={() => deleteRow()}>
                        Delete
                    </Button>,
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                ]}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Are you sure you want to delete this industry?</p>
                </div>
            </Modal>
        </>
    )
}  