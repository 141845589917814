export const updateDataSource = (dataSource, id, checked, type) => {

    const updatedItems = dataSource.map(item => {
        if (item.id === id) {
            return { ...item, [type]: checked };
        }
        return item;
    });

    return updatedItems;
};