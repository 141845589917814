import React from 'react';
import { Typography, Button, Space, Table, Card } from 'antd';
import { useHistory } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import windowSize from 'react-window-size';
import moment from 'moment';
import { PAGINATION_OPTIONS } from '../../constants';

const { Title, Text } = Typography;

const Branch = ({ branches, store_id, windowWidth }) => {

  const isMobile = windowWidth <= 768;

  let history = useHistory();

  const columns = [
    {
      title: '#',
      key: 'sno',
      render: (text, object, index) => index + 1
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Address',
      key: 'address',
      render: (text, record) => (<Text>{record.address}, <span style={{ color: 'blue' }}>{record.country}</span></Text>)
    },
    {
      title: 'Contact',
      key: 'email',
      render: (text, record) => (
        <>
          <Text>{record.email}</Text>
          <br />
          <Text>{record.phone}</Text>
        </>
      )
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Opening time',
      dataIndex: 'opening_time',
      key: 'openning_time',
      render: (openning_time) => (<Text>{moment(openning_time, "h:mm:ss").format('h:mm a')}</Text>)
    },
    {
      title: 'Closing time',
      dataIndex: 'closing_time',
      key: 'closing_time',
      render: (closing_time) => (<Text>{moment(closing_time, "h:mm:ss").format('h:mm a')}</Text>)
    },
    {
      title: 'Main',
      dataIndex: 'is_main',
      key: 'is_main',
      render: (is_main) => <Text>{is_main ? 'Yes' : 'No'}</Text>
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (active) => <Text>{active ? 'Yes' : 'No'}</Text>
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      with: 100,
      render: (text, record) => (
        <Space size='small'>
          <Button onClick={() => history.push({ pathname: "/branches/" + store_id + "/view/" + record.id })}>View</Button>
        </Space>
      ),
    },
  ];


  const renderHeader = () => {
    return <Title level={3}>Branches</Title>
  }

  const renderBranch = () => {
    return (
      <>
        <Table
          dataSource={branches}
          columns={columns}
          title={() => renderHeader()}
          pagination={{ ...PAGINATION_OPTIONS }}
        />
      </>
    )
  }

  const renderBranchMobile = () => {
    return (
      <>
        {
          branches.map(branch => {
            return (
              <>
                <br />
                <Card
                  style={{ width: '100%' }}
                  actions={[
                    <Space size='small'>
                      <Button type='primary' icon={<EyeOutlined />} onClick={() => history.push({ pathname: "/branches/" + store_id + "/view/" + branch.id })}>View</Button>
                    </Space>
                  ]}>
                  <Text style={{ fontWeight: 'bolder' }}>Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch.name}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Address : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch.address}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Country : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch.country}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Email : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch.email}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Phone : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch.phone}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Currency : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch.currency}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Opening Time : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch.opening_time}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Closing Time : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch.closing_time}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Main : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch.is_main ? 'Yes' : 'No'}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Active : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch.active ? 'Yes' : 'No'}</span></Text>
                  <br />
                </Card>
                <br />
              </>
            );
          })
        }
      </>
    )
  }

  const renderContent = () => {
    if (isMobile) {
      return renderBranchMobile()
    } else {
      return renderBranch()
    }
  }

  return (
    <>
      {isMobile && renderHeader()}
      {renderContent()}
    </>
  );

}

export default windowSize(Branch)