import { Avatar, Button, Modal, notification, Popconfirm, Space, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { CloseCircleOutlined, DeleteOutlined, SendOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { PAGINATION_OPTIONS } from "../../constants";
import { deleteNewsletter, getNewsletters, sendToSubscriberNewsletter } from "../../data";
import { ajaxService } from "../../services";

const { Title } = Typography;

export default function Newsletters() {

    let history = useHistory()
    const [dataSource, setDataSource] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(true);

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (image) => <Avatar shape="square" size={64} src={image ? ajaxService.getImage(image) : null} />
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
            width: '50%',
            render: (content) => <div style={{ width: '800px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} dangerouslySetInnerHTML={{ __html: content }} />

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                return (<Tag color={status ? 'green' : 'geekblue'}>{status ? 'Published' : 'Draft'}</Tag>)
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, { id, status }) => (
                <Space size='small'>
                    <Popconfirm
                        placement="topLeft"
                        title="Are you sure you want to send this newsletter to subscribers?"
                        onConfirm={() => handleSendToSubscriber(id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="primary"
                            icon={<SendOutlined />}
                            style={{ display: status ? '' : 'none' }}
                        >
                            Send To Subscribers
                        </Button>
                    </Popconfirm>
                    <Space style={{ position: 'relative', right: 8 }}>
                        <Button style={{ display: status ? 'none' : '' }} onClick={() => history.push({ pathname: "/newsletters/form/" + id })}>Edit</Button>
                        <Button style={{ display: status ? 'none' : '' }} onClick={() => openDeleteModal(id)}>Delete</Button>
                    </Space>
                </Space>
            ),
        },
    ]

    const handleSendToSubscriber = async (id) => {
        const response = await sendToSubscriberNewsletter(id)
        if (response) {
            setShowModal(false)
            notification.open({ message: 'This newsletter has been successfully sent to subscribers', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to send newsletter', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const deleteRow = async () => {
        let newsletter_id = localStorage.getItem('newsletter')
        const data = await deleteNewsletter(newsletter_id);

        if (data) {
            setShowModal(false)
            setForceRefresh(true)
            notification.open({ message: 'Newsletter has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to deleted newsletter', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('newsletter', id);
        setShowModal(true)
    }

    const initialize = async () => {
        const data = await getNewsletters(forceRefresh);
        if (data) {
            setDataSource(data);
            setForceRefresh(false);
        }
    }

    useEffect(() => {
        if (forceRefresh) {
            initialize();
        }
    }, [forceRefresh])

    const renderHeader = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Newsletters</Title>
            <Space size="small" >
                <Button type="primary" onClick={() => history.push({ pathname: '/newsletters/form/0' })}>Add Newsletter</Button>
            </Space>
        </div>
    )

    return (
        <>
            <Table
                dataSource={dataSource}
                columns={columns}
                title={() => renderHeader()}
                pagination={{ ...PAGINATION_OPTIONS }}
                rowKey='id'
            />
            <Modal
                title="Delete Newsletter"
                centered
                open={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button key="delete" onClick={() => deleteRow()}>
                        Delete
                    </Button>,
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                ]}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Are you sure you want to delete this newsletter?</p>
                </div>
            </Modal>
        </>
    )
}  