import React, { useEffect, useState, } from 'react';
import { Form, Input, Button, notification, Row, Col, Space, Select, Divider, Switch } from 'antd';
import { useHistory, useParams } from "react-router-dom";
import { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { getOwner, getUser, updateOwner, } from '../../data';
import windowSize from 'react-window-size';

const { Option } = Select

const OwnerForm = ({ windowWidth }) => {

    const isMobile = windowWidth <= 768
    let history = useHistory();
    const [form] = Form.useForm();
    const { storeId, id } = useParams();

    const [passwordField, setPasswordField] = useState([])

    const onFinish = async (values) => {
        if (id > 0) {
            const updateData = await updateOwner(id, values)
            if (updateData) {
                history.push({ pathname: '/stores/view/' + storeId });
                notification.open({ message: 'User has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update user', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
    };

    const loadData = async (id) => {
        if (id > 0) {
            const data = await getOwner(id)
            if (data) {
                form.setFieldsValue({ ...data })
            }
        }

    }

    useEffect(() => {
        loadData(id);
    }, []);

    const renderUserForm = () => {
        return (
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={{
                    verified: true
                }}
            >
                <Row gutter={16} >
                    <Col span={6}></Col>
                    <Col span={12}>
                        <Row gutter={16}>
                            <Divider plain> Basic Information </Divider>
                            <Col span={12}>
                                <Form.Item name='name' label="Name" rules={[
                                    {
                                        required: true,
                                        message: 'Name  is required',
                                    },
                                ]}>
                                    <Input placeholder="Name" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name='phone' label="Phone Number" rules={[{ required: true, message: 'Phone Number is required' }]}>
                                    <Input placeholder="Phone Number" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name='gender' label="Gender" rules={[{ required: true, message: 'Gender is required' }]} >
                                    <Select placeholder={'Select Gender'}>
                                        <Option value={'Male'} key={'male'}>Male</Option>
                                        <Option value={'Female'} key={'female'}>Female</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name='city' label="City" rules={[{ required: true, message: 'City is required' }]} >
                                    <Input placeholder='City' />
                                </Form.Item>
                            </Col>

                            <Divider plain>Account Details</Divider>
                            <Col span={12}>
                                <Form.Item name='email' label="Email" rules={[
                                    {
                                        required: true,
                                        message: 'Please enter a valid email address',
                                        type: 'email'
                                    },
                                ]}>
                                    <Input placeholder="Email" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name='password' label='Password'
                                    rules={[
                                        {
                                            min: 8,
                                            message: 'Password must be at least 8 characters'
                                        }
                                    ]}>
                                    <Input.Password placeholder='Password' onChange={e => setPasswordField(e.target.value)} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name='confirm_password' label='Confirm Password'
                                    rules={[
                                        passwordField.length > 0 &&
                                        {
                                            required: true,
                                            message: 'Confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error("Confirm password does not match to password"));
                                            },
                                        }),
                                    ]}>
                                    <Input.Password placeholder='Confirm Password' disabled={passwordField.length > 0 ? false : true} />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="verified" label="Email Verified" valuePropName="checked">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col span={6}></Col>
                </Row >
            </Form >
        );
    }

    return renderUserForm()

};
export default windowSize(OwnerForm)