import React, { useEffect, useState } from "react";
import { Table, Space, Button, Typography, Switch, notification, Alert } from "antd"
import { CheckOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { getStoreSettingsGlobal, updateAbility, updateStoreSettingGlobal } from "../../data";
import { PAGINATION_OPTIONS } from "../../constants";
const { Title, Text } = Typography

const Features = () => {

    let history = useHistory()

    const [forceRefresh, setForceRefresh] = useState(true)
    const [developer, setDeveloper] = useState(false)

    const [dataSource, setDataSource] = useState([])

    const color = (item) => {
        if (item) {
            return 'blue';
        } else {
            return 'red'
        }
    }

    const text = (item) => (
        <Text style={{ color: color(item) }}>{item ? 'Yes' : 'No'}</Text>
    )

    const toggle = (row, action, type) => {
        return <Switch
            onChange={(checked) => updateValue(row, checked, type)}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            // checked={action ? true : false}
            defaultChecked={action ? true : false}
        />
    }

    const updateValue = async (row, checked, type) => {

        let { id, slug, title } = row;

        const active = await updateStoreSettingGlobal(id, { type: type, value: checked, slug: slug })

        if (active) {
            setForceRefresh(true)

            if (type === 'is_default') {
                if (checked === true) {
                    notification.open({
                        message: title + ' has been assigned to all stores.',
                        icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />
                    })
                } else if (checked === false) {
                    notification.open({
                        message: title + ' has been removed from all stores.',
                        icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />
                    })
                }

            } else {
                if (checked === true) {
                    notification.open({
                        message: title + ' is now available.',
                        icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />
                    })
                } else if (checked === false) {
                    notification.open({
                        message: title + ' is now unavailable.',
                        icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />
                    })
                }
            }
        }
        else {
            notification.open({ message: 'Unable to update', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const updateAbilityValue = async (id, checked, type) => {
        const active = await updateAbility(id, { type: type, value: checked })
        if (active) {
            setForceRefresh(true)
            if (checked === true) {
                notification.open({ message: 'This permission has been set to is default', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else if (checked === false) {
                notification.open({ message: 'This permission has not set to is default', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            }
        }
        else {
            notification.open({ message: 'Unable to update', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const expandedFeaturePermissions = (abilities) => {
        const abilitiesColumns = [
            {
                title: 'S.No',
                key: 'title',
                render: (text, record, index) => index + 1,
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title'
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Is Default',
                dataIndex: 'is_default',
                key: 'is_default',
                render: (is_default, record) => <Switch
                    onChange={(checked) => updateAbilityValue(record.id, checked, 'is_default')}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={is_default ? true : false} />
            },
        ];

        if (abilities && abilities.length > 0) {
            return <Table columns={abilitiesColumns} dataSource={abilities} pagination={false} rowKey='id' />;
        }

        return null;
    };

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            hidden: false,
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
            key: 'slug',
            hidden: true
        },
        {
            title: 'Route',
            dataIndex: 'route',
            key: 'route',
            hidden: true
        },
        {
            title: 'Related Modules',
            dataIndex: 'tags',
            key: 'tags',
            hidden: false
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            hidden: true
        },
        {
            title: 'Available',
            dataIndex: 'available',
            key: 'available',
            render: (available, record, row) => {
                if (developer) {
                    return toggle(record, available, 'available')
                } else {
                    return text(available)
                }
            },
            hidden: true
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (active, record) => {
                if (developer) {
                    return text(active)
                } else {
                    return toggle(record, active, 'active')
                }
            },
            hidden: true
        },
        {
            title: 'Assign to all Stores',
            dataIndex: 'is_default',
            key: 'is_default',
            render: (is_default, record) => {
                if (developer) {
                    return text(is_default)
                } else {
                    return toggle(record, is_default, 'is_default')
                }
            },
            hidden: false
        },
        {
            title: 'Action',
            key: 'operation',
            render: (text, record) => {
                return (
                    <Space size='small'>
                        <Button onClick={() => history.push({ pathname: '/features/form/' + record.id })}>Edit</Button>
                    </Space>)
            },
            hidden: true,
        },
    ].filter(item => developer ? item : !item.hidden);

    const initialize = async () => {
        const response = await getStoreSettingsGlobal(forceRefresh)
        if (response) {
            let { developer, data } = response;
            setDeveloper(developer)

            // let rows = developer ? data : data.filter(i => i.display)

            setDataSource(data)
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        initialize()
        setForceRefresh(false)
    }, [forceRefresh])

    const renderHeader = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', alignItems: 'start' }}>
                <Title level={3}>Features</Title>
                {!developer && <Alert message="Toggling field `Active` will enable/disable the feature for all stores" type="error" style={{ marginBottom: 10 }} />}
                {!developer && <Alert message="Toggling field `Marked as Default` will assign the feature to store when registered for the first time" type="info" />}
            </div>
            <Space size="small">
                {
                    developer &&
                    <Button type="primary" onClick={() => history.push({ pathname: '/features/form/0' })}>Add Feature</Button>
                }
            </Space>
        </div>
    )

    const renderPagination = () => {
        if (developer) {
            return { ...PAGINATION_OPTIONS }
        }

        return false;
    }

    return (
        <>
            <Table
                title={() => renderHeader}
                columns={columns}
                expandable={developer ? {
                    expandedRowRender: (record) => expandedFeaturePermissions(record.abilities),
                } : false}
                dataSource={dataSource}
                pagination={renderPagination()}
                rowKey="id"
            />
        </>
    )
}

export default Features