import React, { useEffect, useState } from "react";
import { Table, Typography } from "antd";
import { PAGINATION_OPTIONS } from "../../constants";
import { subscribers } from "../../data";
import moment from "moment";

const { Title, Text } = Typography;

export default function NewslettersSubscribers() {

    const [dataSource, setDataSource] = useState([])

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            // width: '50%',
            render: (date) => <Text>{moment(date).format('DD-MMM-yyy')}</Text>
        }
    ]

    const initialize = async () => {
        const data = await subscribers();
        if (data) {
            setDataSource(data);
        }
    }

    useEffect(() => {
        initialize();
    }, [])

    return (
        <>
            <Table
                dataSource={dataSource}
                columns={columns}
                title={() => <Title level={3}>Newsletters Subscribers</Title>}
                pagination={{ ...PAGINATION_OPTIONS }}
            />
        </>
    )
}  