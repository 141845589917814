import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const removeDependency = () => { ajaxService.removeDependency() }

export async function getAbilities(refresh = false) {
    removeDependency()
    if (refresh) {
        return await loadResource(urlService.permissions, 'permissions');
    } else {
        let permissions = await loadState('permissions');

        if (permissions == null) {
            return await loadResource(urlService.permissions, 'permissions');
        } else {
            return Response(permissions);
        }
    }
}

export async function updateAbility(id, data) {
    removeDependency()
    return ajaxService.put(urlService.permissions + '/' + id, data).then(response => {
        return Response(response.data);
    })
}