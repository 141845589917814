import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const removeDependency = () => ajaxService.removeDependency()

export async function getStoreSettingsGlobal(refresh = false, id = 0) {
    removeDependency()
    if (refresh) {
        return await loadResource(`${urlService.storeSettingsGlobal}?store_id=${id}`, 'storeSettingsGlobal');
    } else {
        let storeSettingsGlobal = await loadState('storeSettingsGlobal');

        if (storeSettingsGlobal == null) {
            return await loadResource(urlService.storeSettingsGlobal, 'storeSettingsGlobal');
        } else {
            return Response(storeSettingsGlobal)
        }
    }
}

export async function getStoreSettingGlobal(id) {
    removeDependency()
    let storeSettingsGlobal = await loadState('storeSettingsGlobal');
    if (storeSettingsGlobal !== null) {
        return storeSettingsGlobal.data.find(i => i.id == id);
    } else {
        return ajaxService.get(urlService.storeSettingsGlobal + '/' + id).then(response => {
            return Response(response.data);
        })
    }
}


export async function saveStoreSettingGlobal(data) {
    removeDependency()
    return ajaxService.post(urlService.storeSettingsGlobal, data).then(response => {
        return Response(response.data);
    })
}

export async function updateStoreSettingGlobal(id, data) {
    removeDependency()
    return ajaxService.put(urlService.storeSettingsGlobal + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function getStoreSettings(id) {
    return ajaxService.get('stores/' + id + '/' + urlService.storeSettings).then(response => {
        return Response(response.data);
    })
}