import { AutoComplete, Button, Col, Form, Input, Radio, Row, Select, Space, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { getPaymentTransactionFilter, getPaymentTransactions, getStores } from "../../data";
import moment from "moment";

const { Title, Text } = Typography;
const { Option } = Select;

const Transactions = () => {
    const [form] = Form.useForm()
    const [dataSource, setDataSource] = useState([])
    const [customers, setCustomers] = useState([])
    const [stores, setStores] = useState([])
    const [orderNumbers, setOrderNumbers] = useState([])
    const [forceRefresh, setForceRefresh] = useState(true);

    const columns = [
        {
            title: 'S.No',
            key: 'index',
            render: (text, record, index) => <Text>{index + 1}</Text>
        },
        {
            title: 'Order #',
            dataIndex: 'order_number',
            key: 'order_number',
        },
        {
            title: 'Store / Branch',
            dataIndex: 'store_name',
            key: 'store_name',
            render: (storeName, record) => (
                <>
                    <Text>{storeName} /</Text>
                    <br />
                    <Text>{record.branch_name}</Text>
                </>
            )
        },
        {
            title: 'Customer',
            dataIndex: 'customer_name',
            key: 'customer_name',
        },
        {
            title: 'Order Type',
            dataIndex: 'order_type',
            key: 'order_type',
            render: (type) => <Text>{type == "Ecommerce" ? 'Web' : 'App'}</Text>
        },
        {
            title: 'TransactionID',
            dataIndex: 'transaction_id',
            key: 'transaction_id',
        },
        {
            title: 'Transaction Amount',
            dataIndex: 'transaction_amount',
            key: 'transaction_amount',
            render: (amount) => <Text style={{ float: 'right' }}>{amount}</Text>
        },
        {
            title: 'Payment Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Date and time',
            dataIndex: 'date_time',
            key: 'date_time',
            render: (dateTime) => (
                <>
                    <Text>{moment(dateTime).format('DD-MMM-Y')}</Text>
                    <br />
                    <Text>{moment(dateTime).format('hh:mm A')}</Text>
                </>
            )
        },
        {
            title: 'Status',
            dataIndex: 'success',
            key: 'success',
            render: (success) => {
                return (<Text style={{ color: success == 0 ? 'red' : 'green' }}>{success == 1 ? "Succeeded" : "Failed"}</Text>)
            }
        },
    ]

    const handleSearch = async (values) => {
        const response = await getPaymentTransactionFilter(values)
        if (response) {
            setDataSource(response)
        }
    }

    const loadStores = async () => {
        const response = await getStores(forceRefresh, '?all=true');
        if (response) {
            setStores(response['stores']);
            setForceRefresh(false)
        }
    }

    const initialize = async () => {
        const response = await getPaymentTransactions(forceRefresh)
        if (response) {
            setDataSource(response)
            setForceRefresh(false)

            const uniqueCustomer = [...new Set(response.map(i => i.customer_name))];
            setCustomers(uniqueCustomer)

            const uniqueOrderNumbers = [...new Set(response.map(i => i.order_number))];
            setOrderNumbers(uniqueOrderNumbers)

        }
    }

    useEffect(() => {
        initialize();
        loadStores();
    }, [])

    const [filteredData, setFilteredData] = useState([]);

    const handleAutoComplete = (value) => {
        if (value === '') {
            setFilteredData([]);
        } else {
            const filtered = options.filter(
                (option) =>
                    option.value.toLowerCase().indexOf(value.toLowerCase()) !== -1
            );
            setFilteredData(filtered);
        }
    }

    const options = orderNumbers.map((item) => ({ value: item }));

    const renderFilter = (
        <Form layout={'vertical'} form={form} onFinish={handleSearch}>
            <Row gutter={16}>
                <Col span={6} >
                    <Form.Item label="Order Number" name="order_number">

                        <AutoComplete
                            allowClear
                            placeholder="Enter Order Number"
                            options={filteredData}
                            onSearch={handleAutoComplete}
                            // defaultOpen={true}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                    </Form.Item>
                </Col>

                <Col span={6} >
                    <Form.Item label="Customer" name="customer_name">
                        <Select
                            showSearch
                            allowClear
                            placeholder="Select Customer Name"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {customers.map((customer, index) => {
                                return <Option value={customer} key={"customer-" + index} >{customer}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={6} >
                    <Form.Item label="Store" name="store_id">
                        <Select
                            showSearch
                            allowClear
                            placeholder="Select Store Name"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {stores.map(({ id, name }, index) => {
                                return <Option value={id} key={"store-" + index} >{name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={6} >
                    <Form.Item label="Order Type" name="order_type">
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="mobile">Mobile</Radio.Button>
                            <Radio.Button value="ecommerce">Ecommerce</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                {/* <Col span={6}>
                    <Form.Item label="Date Range" >
                        <RangePicker style={{ width: '100%' }} value={dateRange} onChange={(e) => setDateRang(e)} />
                    </Form.Item>
                </Col> */}

                <Col span={6}>
                    {/* <Form.Item label={<Text>&nbsp;</Text>}> */}
                    <Form.Item>
                        <Space>
                            <Button htmlType="submit" type="primary">Search</Button>
                            <Button onClick={() => { form.resetFields(); handleSearch() }}>Clear</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )

    return (
        <>
            {renderFilter}
            <Table
                dataSource={dataSource}
                columns={columns}
                title={() => <Title level={3}>Online Payment Transactions</Title>}
                // pagination={{ ...PAGINATION_OPTIONS }}
                pagination={false}
                rowKey='id'
            />
        </>
    )
}

export default Transactions