
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography, Table } from 'antd';
import { getChartFilter, getDashboard } from '../data';
import Graph from './chart';

const { Title, Text } = Typography

const columns = [
  {
    title: "Name",
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: "Domain",
    dataIndex: 'domain',
    key: 'domain',
  },
  {
    title: "Slogan",
    dataIndex: 'slogan',
    key: 'slogan',
  },
  {
    title: "Industry",
    dataIndex: 'industry',
    key: 'industry',
  },
]

export default function Dashboard() {

  // For cards
  const [activeStore, setActiveStore] = useState(0)
  const [abandonedStores, setAbandonedStores] = useState(0)
  const [incompleteRegistration, setIncompleteRegistration] = useState(0)
  const [activeDemoUsers, setActiveDemoUsers] = useState(0)
  const [openTickets, setOpenTickets] = useState(0)
  const [featureRequests, setFeatureRequests] = useState(0)
  const [newsletterSubscribers, setNewsletterSubscribers] = useState(0)
  // For chart
  const [activeStoreChart, setActiveStoreChart] = useState([])
  const [incompleteRegistrationChart, setIncompleteRegistrationChart] = useState([])
  const [activeDemoUsersChart, setActiveDemoUsersChart] = useState([])
  const [openTicketsChart, setOpenTicketsChart] = useState([])
  const [featureRequestsChart, setFeatureRequestsChart] = useState([])
  const [newsletterSubscribersChart, setNewsletterSubscribersChart] = useState([])
  // const [abandonedStores, setAbandonedStores] = useState([])

  const [months, setMonths] = useState([])

  const data = [
    { heading: 'Total Approved Stores:', color: 'Green', total: activeStore, link: 'stores?type=approved' },
    { heading: 'Total Abandoned Stores:', color: 'Red', total: abandonedStores, link: 'stores?type=abandoned' },
    { heading: 'Total Pending Stores:', color: 'Orange', total: incompleteRegistration, link: 'incomplete-registration' },
    // { heading: 'Active Demo Users:', color: '#003366', total: activeDemoUsers },
    // { heading: 'Open Tickets:', color: 'Purple', total: openTickets },
    // { heading: 'Feature Requests:', color: 'Teal', total: featureRequests },
    // { heading: 'Active Newsletter Subscribers:', color: 'Brown', total: newsletterSubscribers, link: 'newsletters-subscriber' },
  ];

  const handleClick = async (type) => {
    const response = await getChartFilter({ type: type })
    if (response) {
      setMonths(response)
    }
  }

  const initialize = async () => {
    let data = await getDashboard();
    if (data) {
      let {
        // For cars
        activeStore, incompleteRegistration, activeDemoUsers, abandonedStores, openTickets, featureRequests, newsletterSubscribers,
        // For chart
        activeStoreChart, incompleteRegistrationChart, activeDemoUsersChart, openTicketsChart, featureRequestsChart, newsletterSubscribersChart,// abandonedStoresChart
        // Months
        months
      } = data;

      // For chart
      setActiveStoreChart(activeStoreChart)
      setIncompleteRegistrationChart(incompleteRegistrationChart)
      setActiveDemoUsersChart(activeDemoUsersChart)
      setOpenTicketsChart(openTicketsChart)
      setFeatureRequestsChart(featureRequestsChart)
      setNewsletterSubscribersChart(newsletterSubscribersChart)
      // For card
      setActiveStore(activeStore)
      setIncompleteRegistration(incompleteRegistration)
      setActiveDemoUsers(activeDemoUsers)
      setAbandonedStores(abandonedStores)
      setOpenTickets(openTickets)
      setFeatureRequests(featureRequests)
      setNewsletterSubscribers(newsletterSubscribers)

      setMonths(months)
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  const renderPendingStoresTable = () => (
    <>
      <Table
        title={() => <Title level={3}>Pending Stores</Title>}
        columns={columns}
      // dataSource={}
      />
    </>
  )

  return (
    <Card>
      <Title level={3}>Dashboard</Title>
      <Row gutter={[16, 18]}>
        {data.map((card, index) => (
          <Col span={6} key={index}>
            <a href={card.link} style={{ cursor: card.link ? 'pointer' : 'context-menu' }} >
              <Card key={index} style={{ backgroundColor: card.color }}>
                <Title level={5} style={{ color: '#FFFFFF', margin: 0 }}>{card.heading}</Title>

                {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ color: '#FFFFFF', margin: 0, }}>
                  <label>This Month</label>
                  <Text> {card.total}</Text>
                </div>
                <div style={{ color: '#FFFFFF', margin: 0, }}>
                  <label>This Day</label>
                  <Text> {card.total}</Text>
                  </div>
                <div style={{ color: '#FFFFFF', margin: 0, }}>
                <label>This Week</label>
                <Text> {card.total}</Text>
                </div>
              </div>
            <br /> */}

                <p style={{ color: '#FFFFFF', margin: 0, textAlign: 'right' }}>{card.total}</p>

              </Card>
            </a>

          </Col>
        ))
        }
      </Row >
      <br /><br />
      {
      //renderPendingStoresTable()
      }
      <br /><br />
      {/* Chart */}
      {/* <Graph
        activeStoreChart={activeStoreChart}
        incompleteRegistrationChart={incompleteRegistrationChart}
        activeDemoUsersChart={activeDemoUsersChart}
        openTicketsChart={openTicketsChart}
        featureRequestsChart={featureRequestsChart}
        newsletterSubscribersChart={newsletterSubscribersChart}
        months={months}
        handleClick={handleClick}
      /> */}

    </Card >
  )
}