import { useEffect, useState } from "react"
import { CheckOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Table, Typography, Switch, notification, Radio } from "antd"
import { useHistory, useLocation } from "react-router-dom"
import { getStores, updateStore } from "../../data"
import { PAGINATION_OPTIONS } from "../../constants";
import { ajaxService } from "../../services";

const { Title } = Typography

function Stores() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const params = queryParams.get('type');

    let history = useHistory()

    const [forceRefresh, setForceRefresh] = useState(true)
    const [type, setType] = useState(params == 'approved' ? 2 : params == 'abandoned' ? 3 : 1)

    const [stores, setStores] = useState([])
    const [activeStores, setActiveStores] = useState([])
    const [abandonedStores, setAbandonedStores] = useState([])

    const updateValue = async (id, checked, type) => {

        const updatedStores = stores.map(store => {
            if (store.id === id) {
                return { ...store, active: checked };
            }
            return store;
        });
        setStores(updatedStores);

        const active = await updateStore(id, { type: type, value: checked })
        if (active) {
            setForceRefresh(true)
            notification.open({ message: 'Store has been ' + (checked ? 'approved' : 'disapproved'), icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        }
        else {
            notification.open({ message: 'Unable to update active', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Domain',
            dataIndex: 'domain',
            key: 'domain',
        },
        {
            title: 'Logo',
            dataIndex: 'logo',
            key: 'logo',
            render: (logo) => <Avatar shape="square" size={64} src={logo ? ajaxService.getImage(logo) : null} />
        },
        {
            title: 'Plan',
            dataIndex: 'plan',
            key: 'plan',
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (active, record, index) => {
                return (
                    <Switch
                        checked={active}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={(checked) => updateValue(record.id, checked, 'active')}
                    />
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                // <Space size='small'>
                <Button onClick={() => history.push({ pathname: '/stores/view/' + record.id })}>Details</Button>
                // {/* <Button onClick={() => history.push({ pathname: '/stores/permissions/' + record.id })}>Permissions</Button> */ }
                // </Space>
            )
        },
    ]

    const initialize = async () => {
        const data = await getStores(forceRefresh, '?all=true');

        if (data) {

            let stores = data['stores']

            setStores(stores);
            setActiveStores(stores.filter(i => i.active == 1));
            setAbandonedStores(data['abandoned'])

            setForceRefresh(false)
        }
    }

    useEffect(() => {
        if (forceRefresh) {
            initialize();
            setForceRefresh(false)
        }

    }, [forceRefresh])

    const renderHeader = (
        <>
            <Title level={3}>Stores</Title>

            <Radio.Group optionType="button" buttonStyle="solid" defaultValue={type} onChange={(e) => { setType(e.target.value); setForceRefresh(true) }}>
                <Radio value={1}>All</Radio>
                <Radio value={2}>Approved</Radio>
                <Radio value={3}>Abandoned</Radio>
            </Radio.Group>
        </>
    )

    return (
        <Table
            dataSource={type == 1 ? stores : type == 2 ? activeStores : abandonedStores}
            columns={columns}
            title={() => renderHeader}
            rowKey="id"
            pagination={{ ...PAGINATION_OPTIONS }}
        />
    )
}

export default Stores