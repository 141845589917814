import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom"
import { Button, Col, Input, Row, Space, Form, Select, notification, Divider, Typography } from "antd"
import { CheckCircleOutlined, CloseCircleOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { getAbilities, getStoreSettingGlobal, saveStoreSettingGlobal, updateStoreSettingGlobal } from "../../data";

const { Option } = Select
const { Text } = Typography

const FeatureForm = () => {

    const [form] = Form.useForm();
    let history = useHistory();
    let { id } = useParams()

    const [text, setText] = useState("")
    const [forceRefresh, setForceRefresh] = useState(true);
    const [ability, setAbility] = useState({ name: '', title: '', id: 0 });

    const [abilities, setAbilities] = useState([])

    const onFinish = async (values) => {

        const data = { ...values, description: text };

        if (id == 0) {
            const saveData = await saveStoreSettingGlobal(data);
            if (saveData) {
                history.push({ pathname: "/features" });
                notification.open({ message: 'Feature has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add feature', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
        else {
            const updateData = await updateStoreSettingGlobal(id, data);
            if (updateData) {
                history.push({ pathname: '/features' });
                notification.open({ message: 'Feature has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update feature', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const handleFeatureTitleChange = (text) => {
        const transformedText = text.toLowerCase().replace(/\s+/g, '-');
        form.setFieldsValue({ title: text, slug: transformedText });
    }

    const handlePermissionTitleChange = (changedValue, allValues) => {
        const { permissions } = allValues;

        const updatedPermissions = permissions.map((permission) => ({
            ...permission,
            name: permission.title.toLowerCase().replace(/\s+/g, '-'),
        }));

        form.setFieldsValue({ permissions: updatedPermissions });
    };

    const handleChange = (e) => {
        if (e) {
            let ability = abilities.find(i => i.id === e)
            setAbility({ name: ability.name, title: ability.title, id: ability.id })
        } else {
            setAbility({ name: '', title: '', id: 0 })
        }
    }

    const handleClick = () => {

        if (!form.getFieldValue('selectedPermission')) {
            setAbility({ name: '', title: '', id: 0 })
        }

        form.resetFields(['selectedPermission'])

    }

    const loadAbilities = async () => {
        const response = await getAbilities(forceRefresh)
        if (response) {
            setAbilities(response);
            setForceRefresh(false)
        }
    }

    const loadData = async (id) => {
        const response = await getStoreSettingGlobal(id)
        if (response) {
            form.setFieldsValue({ ...response, permissions: response.abilities })

            if (response.description !== null) {
                setText(response.description)
            }

        }
    }

    useEffect(() => {
        loadData(id)
        loadAbilities()
    }, [])

    const renderPermissionsForm = (
        <Form.List name="permissions">

            {(fields, { add, remove }) => (
                <>
                    <Form.Item>
                        <Button block style={{ width: 100 }} icon={<PlusOutlined />} onClick={() => { handleClick(); add() }}>
                            Add
                        </Button>
                    </Form.Item>
                    {fields.map(({ key, name, ...restField }) => (
                        <>

                            <Form.Item name={[name, 'id']} initialValue={ability?.id ?? 0} style={{ display: 'none' }}>
                                <input disabled className="productDropDown" />
                            </Form.Item>

                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item label="Title" name={[name, 'title']} initialValue={ability?.title ?? null}>
                                        {/* <Input placeholder="Enter Permission Title" onChange={(e) => handlePermissionTitleChange(e, form.getFieldsValue(true))} /> */}
                                        <Input placeholder="Enter Permission Title" />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label={<Text>Slug</Text>} name={[name, 'name']} initialValue={ability?.name ?? null}>
                                        <Input placeholder="Ex. abc-xyz" />
                                        {/* <Input placeholder="Read Only" readOnly /> */}
                                    </Form.Item>
                                </Col>

                                <Col span={2}>
                                    <Form.Item label=" ">
                                        <DeleteOutlined style={{ color: 'red', fontSize: '20px', }} onClick={() => remove(name)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    ))}
                </>
            )}
        </Form.List>
    )

    return (
        <>
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}>
                <Row gutter={16} >
                    <Col span={6}></Col>
                    <Col span={12} >
                        <Row gutter={16}>

                            <Col span={12}>
                                <Form.Item label="Title" name='title'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Title is required',
                                        }]}
                                >
                                    <Input placeholder="Enter Title" onChange={(e) => handleFeatureTitleChange(e.target.value)} />
                                </Form.Item>
                            </Col>

                            {/* <Col span={12}>
                                <Form.Item label={<Text>Slug <span style={{ color: '#AEC6CF' }}>(Read Only)</span></Text>} name='slug'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Title is required',
                                        }]}
                                >
                                    <Input placeholder="Read Only" readOnly />
                                </Form.Item>
                            </Col> */}

                            <Col span={12}>
                                <Form.Item label="Route" name='route'>
                                    <Input placeholder="Enter Route" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Related Modules" name='tags'>
                                    <Input placeholder="Comma separated tags, eg: admin,pos,website" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Price" name='price'>
                                    <Input placeholder="Enter Price" type="number" step={0.1} />
                                </Form.Item>
                            </Col>

                            <Col span={12}></Col>

                            {/* Toggles */}
                            {/* <Col span={8}>
                                <Form.Item name="available" label="Available" valuePropName="checked">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="active" label="Active" valuePropName="checked">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="is_default" label="Is Default" valuePropName="checked">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />
                                </Form.Item>
                            </Col>*/}

                            <Col span={24}>
                                <Form.Item label="Description" >
                                    <CKEditor
                                        className='editor'
                                        editor={ClassicEditor}
                                        onReady={editor => editor.setData(text)}
                                        onChange={(event, editor) => {
                                            const data = editor.getData()
                                            setText(data)
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            {/* Permissions */}
                            <Divider plain>Permissions</Divider>

                            <Col span={12}>
                                <Form.Item label="Permissions" name={'selectedPermission'}>
                                    <Select allowClear showSearch
                                        placeholder="Select Permission"
                                        onChange={(e) => handleChange(e)}
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {abilities.map(({ id, title }, i) => (
                                            <Option key={`ability-${i}`} value={id}>{title}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            {/* Permissions Form */}
                            <Col span={24}>
                                {renderPermissionsForm}
                            </Col>

                            {/* Buttons */}
                            <Col span={24}>
                                <Form.Item>
                                    <Space>
                                        <Button htmlType="submit" type="primary">Submit</Button>
                                        <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Col>
                    <Col span={6}></Col>
                </Row >
            </Form >
        </>
    )
}

export default FeatureForm