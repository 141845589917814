const UrlService = {
    profile: 'profile',
    user: 'user',
    stores: 'stores',
    branches: 'branches',
    packages: 'packages',
    cuisines: 'cuisines',
    accounts: 'accounts',
    storePermissions: 'stores/permission',
    blogs: 'blogs',
    dashboard: 'super-admin-dashboard',
    newsletters: 'newsletters',
    sendToSubscriber: 'newsletters/sendToSubscriber',
    chartFilter: 'chart-filter',
    storeSettingsGlobal: 'store-settings-global',
    storeSettings: 'store-settings',
    permissions: 'permissions',
    assignSettingsToStore: 'assignSettingsToStore',
    industries: 'industries',
    incompleteRegistration: 'incomplete-registration',
    storesLedger: 'stores-ledger',
    subscribers: 'newsletters-subscribers',
    users: 'users',
    paymentTransactions: 'payment-transactions',
    paymentTransactionFilter: 'payment-transactions/filter',
    storeLimits: 'stores/limits',
}

export default UrlService;