import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom"
import { Button, Col, Input, Row, Space, Form, Select, notification, Switch } from "antd"
import { CheckCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ImageUpload from "../../components/image-upload";
import { getIndustry, saveIndustry, updateIndustry } from "../../data";
import ajaxService, { GlobalVariable } from "../../services/ajax-service";

const { Option } = Select

function IndustryForm() {

    const [form] = Form.useForm();
    let history = useHistory();
    let { id } = useParams()
    const [fileList, setFileList] = useState([]);

    const handleImageUpload = (info) => {
        let fileList = [...info.fileList];
        // Limit file list to 1 item
        fileList = fileList.slice(-1);
        // Update file list state
        setFileList(fileList);
    };

    const onFinish = async (values) => {
        let link = '';

        if (fileList.length > 0) {

            let { response } = fileList[0];

            if (response != undefined) {
                let { success, path } = response;

                if (success) {
                    link = path
                }
            }
            else if (fileList[0].url !== undefined) {
                link = fileList[0].url.replace(GlobalVariable.BASE_IMAGE_URL + '/', "");
            }
        } else {
            link = null;
        }

        let data = { image: link, ...values }

        if (id == 0) {
            const saveData = await saveIndustry(data)
            if (saveData) {
                history.push({ pathname: "/industries" });
                notification.open({ message: 'Industry has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add industry', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
        else {
            const updateData = await updateIndustry(id, data);
            if (updateData) {
                history.push({ pathname: '/industries' });
                notification.open({ message: 'Industry has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update industry', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadData = async (id) => {
        if (id > 0) {
            let data = await getIndustry(id);
            if (data) {

                form.setFieldsValue({ ...data });

                if (data.image) {
                    let imageItem = {
                        uid: data.image,
                        name: 'image.png',
                        status: 'done',
                        url: ajaxService.getImage(data.image),
                        preview: false
                    }
                    setFileList([imageItem]);
                }
            }
        }
    }

    useEffect(() => {
        loadData(id)
    }, [])

    const initialValues = {
        addon: false,
        generic_name: false,
        combo: false,
        expiry_date: false,
        cuisine: false,
        srb: false,
        fbr: false,
    }

    return (
        <>
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={initialValues}
            >
                <Row gutter={16} >
                    <Col span={6}></Col>
                    <Col span={12} >
                        <Row gutter={16}>

                            <Col span={14}>
                                <Form.Item label="Name" name='name' rules={[
                                    {
                                        required: true,
                                        message: 'Name is required',
                                    }]}>
                                    <Input placeholder="Enter Name" />
                                </Form.Item>
                            </Col>
                            <Col span={10} />
                            <Col span={4}>
                                <Form.Item name="addon" label="Addon" valuePropName="checked">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="generic_name" label="Generic Name" valuePropName="checked">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="combo" label="Combo" valuePropName="checked">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="expiry_date" label="Expiry Date" valuePropName="checked">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="cuisine" label="Cuisine" valuePropName="checked">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked
                                    />
                                </Form.Item>
                            </Col>
                            {/* <Col span={4}>
                                <Form.Item name="srb" label="SRB" valuePropName="checked">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="fbr" label="FBR" valuePropName="checked">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked
                                    />
                                </Form.Item>
                            </Col> */}

                            <Col span={24}>
                                <Form.Item label="Image">
                                    <ImageUpload onChange={handleImageUpload} fileList={fileList} crop={true} accept="image/*" />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col span={6}></Col>
                </Row >
            </Form >
        </>
    )
}

export default IndustryForm