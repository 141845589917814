import { Avatar, Button, Modal, notification, Space, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { PAGINATION_OPTIONS } from "../../constants";
import { deleteBlog, getBlogs } from "../../data";
import { ajaxService } from "../../services";

const { Title } = Typography;

export default function Blogs() {

    let history = useHistory()
    const [dataSource, setDataSource] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(true);

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (image) => <Avatar shape="square" size={64} src={ajaxService.getImage(image)} />
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <Tag color={status == 1 ? 'green' : status == 0 ? 'geekblue' : 'red'}>{status == 1 ? 'Published' : status == 0 ? "Draft" : "Unpublished"}</Tag>
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <Space size='small'>
                        <Button onClick={() => history.push({ pathname: '/blogs/form/' + record.id })}>Edit</Button>
                        <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
                    </Space>)
            }
        },
    ]

    const deleteRow = async () => {
        let blog_id = localStorage.getItem('blog')
        const data = await deleteBlog(blog_id);

        if (data) {
            setShowModal(false)
            setForceRefresh(true)
            notification.open({ message: 'Blog has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to deleted blog', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('blog', id);
        setShowModal(true)
    }

    const initialize = async () => {
        const data = await getBlogs(forceRefresh);
        if (data) {
            setDataSource(data);
            setForceRefresh(false);
        }
    }

    useEffect(() => {
        if (forceRefresh) {
            initialize();
        }
    }, [forceRefresh])

    const renderHeader = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Blogs</Title>
            <Space size="small" >
                <Button type="primary" onClick={() => history.push({ pathname: '/blogs/form/0' })}>Add Blog</Button>
            </Space>
        </div>
    )

    return (
        <>
            <Table
                dataSource={dataSource}
                columns={columns}
                title={() => renderHeader()}
                pagination={{ ...PAGINATION_OPTIONS }}
            />
            <Modal
                title="Delete Blog"
                centered
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button key="delete" onClick={() => deleteRow()}>
                        Delete
                    </Button>,
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                ]}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Are you sure you want to delete this blog?</p>
                </div>
            </Modal>
        </>
    )
}  