import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CheckOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Space, Switch, notification, Form } from "antd";
import windowSize from "react-window-size";
import { getCuisine, saveCuisine, updateCuisine } from "../../data";
import ImageUpload from "../../components/image-upload";
import ajaxService, { GlobalVariable } from "../../services/ajax-service";

function CuisineForm({ windowWidth }) {

    const isMobile = windowWidth <= 768
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);

    let history = useHistory()
    let { id } = useParams()

    const handleImageUpload = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setFileList(fileList);
    };

    const onFinish = async (values) => {
        let link = '';

        // let { image } = values;

        // if (typeof image === "string") {
        //     link = image
        // } else {
        //     if (image !== undefined) {
        //         let { response } = image.file;
        //         if (response) {
        //             let { success, path } = response;
        //             if (success) {
        //                 link = path
        //             }
        //         }
        //     }
        // }

        if (fileList.length > 0) {

            let { response } = fileList[0];

            if (response != undefined) {
                let { success, path } = response;

                if (success) {
                    link = path
                }
            }
            else if (fileList[0].url !== undefined) {
                link = fileList[0].url.replace(GlobalVariable.BASE_IMAGE_URL + '/', "");
            }
        } else {
            link = null;
        }

        let data = {
            name: values.name,
            active: values.active,
            meta_title: values.meta_title,
            meta_description: values.meta_description,
            image: link,
        }

        if (id == 0) {
            const saveData = await saveCuisine(data)
            if (saveData) {
                history.push({ pathname: "/cuisines" });
                notification.open({ message: 'Cuisine has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add cuisine', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
        else {
            const updateData = await updateCuisine(id, data);
            if (updateData) {
                history.push({ pathname: '/cuisines' });
                notification.open({ message: 'Cuisine has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update cuisine', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadData = async (id) => {
        if (id > 0) {
            const data = await getCuisine(id)
            if (data) {
                form.setFieldsValue({ ...data });

                if (data.image) {
                    let imageItem = {
                        uid: data.image,
                        name: 'image.png',
                        status: 'done',
                        url: ajaxService.getImage(data.image),
                        preview: false
                    }
                    setFileList([imageItem]);
                }

            }
        }
    }

    useEffect(() => {
        loadData(id)
    }, [])

    const renderCuisineForm = () => (
        <>
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={{
                    active: true
                }}>
                <Row gutter={16} >
                    <Col span={6}></Col>
                    <Col span={12} >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Name" name='name' rules={[
                                    {
                                        required: true,
                                        message: 'Name is required',
                                    }]}>
                                    <Input placeholder="Cuisine name" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Meta Title" name='meta_title'>
                                    <Input placeholder="Meta title" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name='meta_description' label="Meta Description">
                                    <Input placeholder="meta Description" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="active" label="Active" valuePropName="checked">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Image" name="image">
                                    <ImageUpload onChange={handleImageUpload} fileList={fileList} crop={true} accept="image/*" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col span={6}></Col>
                </Row>
            </Form>
        </>
    )

    const renderCuisineFormMobile = () => (
        <>
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={{ active: true }}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Name" name='name' rules={[
                            {
                                required: true,
                                message: 'Name is required',
                            }]}>
                            <Input placeholder="Cuisine name" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Meta Title" name='meta_title'>
                            <Input placeholder="Meta title" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='meta_description' label="Meta Description">
                            <Input placeholder="meta Description" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Image" name="image">
                            <ImageUpload onChange={handleImageUpload} fileList={fileList} crop={true} accept="image/*" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="active" label="Active" valuePropName="checked">
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item>
                            <Space>
                                <Button htmlType="submit" type="primary">Submit</Button>
                                <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return renderCuisineFormMobile()
        } else {
            return renderCuisineForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )

}

export default windowSize(CuisineForm)