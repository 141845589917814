import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const removeDependency = () => ajaxService.removeDependency();

export async function getStoresLedger(refresh = false, param) {
    removeDependency()
    if (refresh) {
        return await loadResource(urlService.storesLedger + param, 'storesLedger');
    } else {
        let storesLedger = await loadState('storesLedger');

        if (storesLedger == null) {
            return await loadResource(urlService.storesLedger + param, 'storesLedger');
        } else {
            return Response(storesLedger);
        }
    }
}

export async function getStoreLedger(id) {
    removeDependency()
    let storesLedger = await loadState('storesLedger');
    if (storesLedger !== null) {
        return storesLedger.find(i => i.id == id);
    } else {
        return ajaxService.get(urlService.storesLedger + '/' + id).then(response => {
            return Response(response.data);
        })
    }
}

export async function saveStoreLedger(data) {
    removeDependency()
    return ajaxService.post(urlService.storesLedger, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteStoreLedger(id) {
    removeDependency()
    return ajaxService.delete(urlService.storesLedger + '/' + id).then(response => {
        return Response(response.data);
    })
}