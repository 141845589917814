import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const removeDependency = () => ajaxService.removeDependency();

export async function getPaymentTransactions(refresh = false) {
    removeDependency()
    if (refresh) {
        return await loadResource(urlService.paymentTransactions, 'paymentTransactions');
    } else {
        let paymentTransactions = await loadState('paymentTransactions');

        if (paymentTransactions == null) {
            return await loadResource(urlService.paymentTransactions, 'paymentTransactions');
        } else {
            return Response(paymentTransactions);
        }
    }
}

export async function getPaymentTransactionFilter(data) {
    removeDependency()
    return ajaxService.post(urlService.paymentTransactionFilter, data).then(response => {
        return Response(response.data);
    })
}