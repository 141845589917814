import { Button, Card, Checkbox, Col, Divider, notification, Row, Space, Typography } from "antd"
import { useState } from "react"
import { useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { getStore, getStorePermissions, saveStorePermissions } from "../../data"
import { CloseCircleOutlined, CheckCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';

const { Title } = Typography
function StorePermissions() {

    let { id } = useParams()
    let history = useHistory()

    const [store, setStore] = useState({})
    const [permissions, setPermissions] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);

    const savePermissions = async () => {

        let params = [];
        permissions.map(permission => {
            if (permission.checked) {
                params.push(permission.id);
            }
        })

        const response = await saveStorePermissions(id, { permissions: params });
        if (response) {
            history.push({ pathname: '/stores/view/' + id });
            notification.open({ message: 'Added permissions for ' + store.name, icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to added permissions for ' + store.name, icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const handleSelectAll = e => {

        const { checked } = e.target

        setIsCheckAll(checked);

        permissions.map(i => {
            i.checked = checked
        });

        if (checked) {
            setIsCheck(permissions.map(li => li.id));
        } else {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        let checkedPermission = permissions.find(i => i.id === id)
        checkedPermission.checked = checked
    };

    const loadStore = async (id) => {
        const response = await getStore(id)
        if (response) {
            setStore(response)
        }
    }

    const initialize = async (id) => {
        const response = await getStorePermissions(id)
        if (response) {
            setPermissions(response)
            setIsCheckAll(response.every(row => row.checked))
        }
    }

    useEffect(() => {
        initialize(id)
        loadStore(id)
    }, [])

    const renderHeader = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Permissions For {store.name}</Title>
            <Button type="primary" onClick={() => history.push({ pathname: '/stores/view/' + id })} icon={<ArrowLeftOutlined />}>Back To Store Detail</Button>
        </div>
    )

    const renderPermissions = () => (
        permissions.map((v, index) => (
            <>
                <Row style={{ padding: '15px 20px' }}>
                    <Col span={1}><label for={v.id} style={{ cursor: 'pointer' }}>{index + 1}</label></Col>
                    <Col span={22}><label for={v.id} style={{ cursor: 'pointer' }}>{v.title}</label></Col>
                    <Col span={1}>
                        <Checkbox key={isCheck.includes(v.id)} id={v.id} onChange={handleClick} defaultChecked={isCheck.includes(v.id) || v.checked ? true : false} />
                    </Col>
                </Row>
                <Divider style={{ padding: '0px', margin: '0' }} />
            </>
        ))
    )

    const renderButtons = () => (
        <div style={{ padding: '10px 20px' }}>
            <Space style={{ float: 'right', marginTop: '10px' }}>
                <Button htmlType="submit" type="primary" onClick={savePermissions}>Submit</Button>
                {/* <Button onClick={() => history.goBack()}>Cancel</Button> */}
            </Space>
        </div>
    )

    return (
        <>
            <Card>
                {renderHeader()}
                {renderButtons()}
                <br />
                <br />
                <Row style={{ backgroundColor: '#fafafa', padding: '15px 20px' }}>
                    <Col span={1}>S.No</Col>
                    <Col span={22}>Title</Col>
                    <Col span={1}>
                        <Checkbox type="checkbox" name="selectAll" id="selectAll" onChange={handleSelectAll} checked={isCheckAll}>
                            All
                        </Checkbox>
                    </Col>
                </Row>
                {renderPermissions()}

                {renderButtons()}
            </Card>
        </>
    )
}

export default StorePermissions