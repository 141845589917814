import React, { useEffect, useState } from "react";
import { Button, Card, Col, DatePicker, Divider, Form, Input, Modal, Row, Select, Space, Table, Typography, Upload, notification } from "antd";
import { CloseCircleOutlined, CheckCircleOutlined, ArrowLeftOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import windowSize from "react-window-size";
import moment from "moment";
import { PAGINATION_OPTIONS } from "../../constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { deleteStoreLedger, getStore, getStoresLedger, saveStoreLedger } from "../../data";
import { ajaxService } from "../../services";

const { Option } = Select
const { Title, Text } = Typography
const { TextArea } = Input

const format = 'DD-MMM-yyy'

function StoreLedger({ windowWidth }) {

    let history = useHistory()

    const isMobile = windowWidth <= 768
    const { id } = useParams()
    const [form] = Form.useForm()

    const [forceRefresh, setForceRefresh] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showDetailsModal, setShowDetailsModal] = useState(false)

    const [type, setType] = useState('')
    const [ledger, setledger] = useState({})

    const [dataSource, setDataSource] = useState([])
    const [branches, setBranches] = useState([])
    const [fileList, setFileList] = useState([])

    const handleChange = (info) => {
        let fileList = [...info.fileList];

        setFileList(fileList);
    }

    const columns = [
        {
            title: 'Date',
            key: 'created_at',
            render: (text, record) => (<Text>{moment(record.date ?? record.created_at).format(format)}</Text>)
        },
        {
            title: 'Branch',
            dataIndex: 'branch_name',
            key: 'branch_name',
            render: (text, record) => (<Text>{record?.branch?.name ?? null}</Text>)
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes'
        },
        {
            title: 'Debit',
            key: 'Debit',
            render: (text, record) => (<Text>{record.cash_in == 0 ? record.amount : ''}</Text>)
        },
        {
            title: 'Credit',
            key: 'credit',
            render: (text, record) => (<Text>{record.cash_in == 1 ? record.amount : ''}</Text>)
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            render: (balance) => (<Text>{balance.toFixed(2)}</Text>)
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <Space>
                    <Button type="primary" onClick={() => openDetailsModal(record.id)}>Details</Button>
                    <Button type="primary" danger style={{ display: record.deletable ? '' : 'none' }} onClick={() => openDeleteModal(record.id)}>Delete</Button>
                </Space>
            )

        },
    ]

    const openDetailsModal = (ledgerID) => {
        const storeLedger = dataSource.find(i => i.id == ledgerID)
        setledger(storeLedger)
        setShowDetailsModal(true)

    }

    const openDeleteModal = (ledgerID) => {
        localStorage.setItem('storeLedger_id', ledgerID);
        setShowDeleteModal(true)
    }

    const deleteRow = async () => {
        let storeLedger_id = localStorage.getItem('storeLedger_id')
        const data = await deleteStoreLedger(storeLedger_id);

        if (data) {
            setShowDeleteModal(false)
            setForceRefresh(true)
            notification.open({ message: 'This row has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to deleted this row', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const onFinish = async (values) => {

        let link = '';
        const { success, path } = fileList[0]?.response || {};
        if (success) link = path;

        let data = {
            ...values,
            type: type,
            store_id: id,
            date: moment(values.date).format(format),
            attachment: link
        }

        const response = await saveStoreLedger(data)
        if (response) {
            setShowModal(false);
            setForceRefresh(true)
            form.resetFields()
            setFileList([])
            notification.open({
                message: type === 'credit' ? 'Cash has been added' : 'Cash has been removed',
                icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />
            })
        } else {
            notification.open({ message: 'Unable to add any transaction', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }

    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const handleClick = (type) => {
        setShowModal(true)
        setType(type)
    }

    const loadStoreBranches = async () => {
        const data = await getStore(id);
        if (data) {
            setBranches(data.branches);
        }
    }

    const initialize = async () => {
        const response = await getStoresLedger(forceRefresh, `?store_id=${id}`)
        if (response) {
            setDataSource(response)
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        if (forceRefresh) {
            initialize();
            setForceRefresh(false)
        }
        loadStoreBranches()
    }, [forceRefresh])

    const renderLedgerTable = (
        <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{ ...PAGINATION_OPTIONS }}
            rowKey='id'
        />
    )

    const renderLedgerModal = (
        <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{ date: moment() }}
        >
            <Modal
                title={type === 'credit' ? 'Add Cash (CR)' : 'Remove Cash (DR)'}
                centered
                open={showModal}
                // open={true}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button key="submit" onClick={form.submit}>
                        Submit
                    </Button>,
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                ]}>

                <Form.Item label="Branch" name="branch_id" labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Branch is required"
                        }
                    ]}
                >
                    <Select placeholder="Select branch">
                        {branches.map((branch) => (
                            <Option value={branch.id}>{branch.name}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Amount" name="amount" labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Amount is required"
                        }
                    ]}
                >
                    <Input type="number" step={0.01} placeholder="Enter amount" />
                </Form.Item>
                <Form.Item label="Date" name="date" labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Date is required"
                        }
                    ]}
                >
                    <DatePicker style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Transaction ID" name="transaction_id" labelCol={{ span: 24 }}>
                    <Input type="number" placeholder="Enter transaction id" />
                </Form.Item>
                <Form.Item label="Notes" name="notes" labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Notes is required"
                        }
                    ]}
                >
                    <TextArea rows={3} placeholder="Enter notes" />
                </Form.Item>
                <Form.Item label="Attachment" labelCol={{ span: 24 }}>
                    <Upload
                        action={ajaxService.getImageUploadUrl}
                        listType="picture"
                        fileList={fileList}
                        onRemove={() => setFileList([])}
                        maxCount={1}
                        onChange={handleChange}
                        method={"post"}
                        name="excel"
                        headers={{
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }}
                    >
                        <Button icon={<UploadOutlined />}>Upload Excel File (Max: 1)</Button>
                    </Upload>
                </Form.Item>
            </Modal>
        </Form >
    )

    const renderButtons = (
        <div style={!isMobile ? { display: 'flex', flexDirection: 'row-reverse' } : {}}>
            <Space style={{ marginRight: '10px' }}>
                <Button type="primary"
                    onClick={() => handleClick('credit')}
                >
                    Add Cash (CR)
                </Button>
                <Button
                    type="primary" danger
                    onClick={() => handleClick('debit')}
                >
                    Remove Cash (DR)
                </Button>
            </Space>
        </div>
    )

    const renderHeader = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Store Ledger</Title>
            <Button type="primary" onClick={() => history.push({ pathname: '/stores/view/' + id })} icon={<ArrowLeftOutlined />}>Back To Store Detail</Button>
        </div>
    )

    // const renderDetails = (
    //     <>
    //         <Text><b>{location.search == '?supplier' ? 'Supplier' : "Customer"} Name : </b> </Text>
    //         <Text>{data.name}</Text>
    //         <br />
    //         <Text><b>Phone Number : </b></Text>
    //         <Text>{data.phone}</Text>
    //         <br />
    //         <Text><b>Email : </b> </Text>
    //         <Text>{data.email}</Text>
    //     </>
    // )

    const renderDeleteModal = (
        <Modal
            title="Delete Row"
            centered
            open={showDeleteModal}
            onCancel={() => setShowDeleteModal(false)}
            footer={[
                <Button key="delete" onClick={() => deleteRow()}>
                    Delete
                </Button>,
                <Button key="back" onClick={() => setShowDeleteModal(false)}>
                    Close
                </Button>
            ]}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Are you sure you want to delete this?</p>
            </div>
        </Modal>
    )

    const renderDetailsModal = (
        <Modal
            title="Details"
            centered
            open={showDetailsModal}
            footer={null}
            onCancel={() => setShowDetailsModal(false)}
            width='50%'
            style={{
                top: '20px',
            }}>
            <Row>
                <Row>
                    <Col span={12}><Title level={5}>Date</Title></Col>
                    <Col span={12}><Text>{moment(ledger.date ?? ledger.created_at).format(format)}</Text></Col>
                    <Divider style={{ marginTop: 10 }} />

                    <Col span={12}><Title level={5}>Branch</Title></Col>
                    <Col span={12}><Text>{ledger?.branch?.name ?? null}</Text></Col>
                    <Divider style={{ marginTop: 10 }} />

                    <Col span={12}><Title level={5}>Order Number</Title></Col>
                    <Col span={12}><Text>{ledger.order_number}</Text></Col>
                    <Divider style={{ marginTop: 10 }} />

                    <Col span={12}><Title level={5}>Transaction ID</Title></Col>
                    <Col span={12}><Text>{ledger.transaction_id}</Text></Col>
                    <Divider style={{ marginTop: 10 }} />

                    <Col span={12}><Title level={5}>Notes</Title></Col>
                    <Col span={12}><Text>{ledger.notes}</Text></Col>
                    <Divider style={{ marginTop: 10 }} />

                    <Col span={12}><Title level={5}>Debit</Title></Col>
                    <Col span={12}><Text>{ledger.cash_in == 0 ? ledger.amount : '0.00'}</Text></Col>
                    <Divider style={{ marginTop: 10 }} />

                    <Col span={12}><Title level={5}>Credit</Title></Col>
                    <Col span={12}><Text>{ledger.cash_in == 1 ? ledger.amount : '0.00'}</Text></Col>
                    <Divider style={{ marginTop: 10 }} />

                    <Col span={12}><Title level={5}>Balance</Title></Col>
                    <Col span={12}><Text>{ledger.balance ? (ledger.balance).toFixed(2) : null}</Text></Col>
                    <Divider style={{ marginTop: 10 }} />

                    <Col span={12}><Title level={5}>Attachment</Title></Col>
                    <Col span={12}><a href={ajaxService.getImage(ledger.attachment)} target="_blank" >{ledger.attachment ? ajaxService.getImage(ledger.attachment) : null}</a></Col>
                </Row>
            </Row>
        </Modal>
    )

    return (
        <>
            <Card>
                {renderHeader}
                <br />
                {/* {renderDetails} */}
                {renderButtons}
                <br />
                {renderLedgerTable}
            </Card>
            {renderDeleteModal}
            {renderDetailsModal}
            {renderLedgerModal}
        </>
    )
}

export default windowSize(StoreLedger)