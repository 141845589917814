import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom"
import { Button, Col, Input, Row, Space, Form, Select, notification, message } from "antd"
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ImageUpload from "../../components/image-upload";
import { getNewsletter, saveNewsletter, updateNewsletter } from "../../data";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { ajaxService } from "../../services";
import { GlobalVariable } from "../../services/ajax-service";

const { Option } = Select

const options = [{ type: 1, status: 'Publish' }, { type: 0, status: 'Draft' }]

function NewsletterForm() {

    const [form] = Form.useForm();
    let history = useHistory();
    let { id } = useParams()

    const [text, setText] = useState("")
    const [fileList, setFileList] = useState([]);

    const handleImageUpload = (info) => {
        let fileList = [...info.fileList];
        // Limit file list to 1 item
        fileList = fileList.slice(-1);
        // Update file list state
        setFileList(fileList);
    };

    const onFinish = async (values) => {
        let link = '';

        if (fileList.length > 0) {

            let { response } = fileList[0];

            if (response != undefined) {
                let { success, path } = response;

                if (success) {
                    link = path
                }
            }
            else if (fileList[0].url !== undefined) {
                link = fileList[0].url.replace(GlobalVariable.BASE_IMAGE_URL + '/', "");
            }
        } else {
            link = null;
        }

        let data = {
            content: text,
            title: values.title,
            meta_title: values.meta_title,
            meta_description: values.meta_description,
            status: values.status,
            image: link,
        }

        if (text === '') {
            return message.error('Please fill in the content box')
        } else {
            if (id == 0) {
                const saveData = await saveNewsletter(data)
                if (saveData) {
                    history.push({ pathname: "/newsletters" });
                    notification.open({ message: 'Newsletter has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
                } else {
                    notification.open({ message: 'Unable to add newsletter', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
                }
            }
            else {
                const updateData = await updateNewsletter(id, data);
                if (updateData) {
                    history.push({ pathname: '/newsletters' });
                    notification.open({ message: 'Newsletter has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
                } else {
                    notification.open({ message: 'Unable to update newsletter', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
                }
            }
        }

    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };


    const loadData = async (id) => {
        if (id > 0) {
            let data = await getNewsletter(id);
            if (data) {

                form.setFieldsValue({ ...data, status: data.status ? "Publish" : "Draft" });

                if (data.image) {
                    let imageItem = {
                        uid: data.image,
                        name: 'image.png',
                        status: 'done',
                        url: ajaxService.getImage(data.image),
                        preview: false
                    }
                    setFileList([imageItem]);
                }

                if (data.content !== null) {
                    setText(data.content)
                }

            }
        }
    }

    useEffect(() => {
        loadData(id)
    }, [])

    return (
        <>
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}>
                <Row gutter={16} >
                    <Col span={6}></Col>
                    <Col span={12} >
                        <Row gutter={16}>

                            <Col span={12}>
                                <Form.Item label="Title" name='title' rules={[
                                    {
                                        required: true,
                                        message: 'Title is required',
                                    }]}>
                                    <Input placeholder="Enter Title" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Meta Title" name='meta_title'>
                                    <Input placeholder="Enter Meta Title" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Meta Decription" name='meta_description'>
                                    <Input placeholder="Enter Meta Description" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Status" name='status'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Status is required'
                                        }
                                    ]}
                                >
                                    <Select placeholder="Select Status">
                                        {
                                            options.map(({ type, status }, i) => (
                                                <Option key={i} value={type}>{status}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item label="Content" >
                                    <CKEditor
                                        className='editor'
                                        editor={ClassicEditor}
                                        onReady={editor => editor.setData(text)}
                                        onChange={(event, editor) => {
                                            const data = editor.getData()
                                            setText(data)
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Image" name="image">
                                    <ImageUpload onChange={handleImageUpload} fileList={fileList} accept="image/*" />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col span={6}></Col>
                </Row >
            </Form >
        </>
    )
}

export default NewsletterForm