import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const removeDependency = () => { ajaxService.removeDependency() }

export async function getNewsletters(refresh = false) {
    removeDependency()
    if (refresh) {
        return await loadResource(urlService.newsletters, 'newsletters');
    } else {
        let newsletters = await loadState('newsletters');

        if (newsletters == null) {
            return await loadResource(urlService.newsletters, 'newsletters');
        } else {
            return Response(newsletters);
        }
    }
}

export async function getNewsletter(id) {
    removeDependency()
    let newsletters = await loadState('newsletters');
    if (newsletters !== null) {
        return newsletters.find(i => i.id == id);
    } else {
        return ajaxService.get(urlService.newsletters + '/' + id).then(response => {
            return Response(response.data);
        })
    }
}

export async function saveNewsletter(data) {
    removeDependency()
    return ajaxService.post(urlService.newsletters, data).then(response => {
        return Response(response.data);
    })
}

export async function updateNewsletter(id, data) {
    removeDependency()
    return ajaxService.put(urlService.newsletters + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteNewsletter(id) {
    removeDependency()
    return ajaxService.delete(urlService.newsletters + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function sendToSubscriberNewsletter(id) {
    removeDependency()
    return ajaxService.post(urlService.sendToSubscriber + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function subscribers() {
    removeDependency()
    return ajaxService.get(urlService.subscribers).then(response => {
        return Response(response.data);
    })
}