import { ajaxService, loadState, urlService } from '../services';
import { loadResource, Response } from '../utility';

const setDependency = () => { ajaxService.setDependency() }

export async function getBranches(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(urlService.branches, 'branches');
    } else {
        let branches = await loadState('branches');

        if (branches == null) {
            return await loadResource(urlService.branches, 'branches');
        } else {
            return Response(branches)
        }
    }
}

export async function getBranch(id) {
    setDependency()
    return ajaxService.get(urlService.branches + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function saveBranch(data) {
    setDependency()
    return ajaxService.post(urlService.branches, data).then(response => {
        return Response(response.data);
    })
}

export async function updateBranch(id, data) {
    setDependency()
    return ajaxService.put(urlService.branches + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteBranch(id) {
    setDependency()
    return ajaxService.delete(urlService.branches + '/' + id).then(response => {
        return Response(response.data);
    })
}
