import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Divider } from 'antd';
import { EnvironmentFilled } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { getBranch } from '../../data';
import windowSize from 'react-window-size';
import moment from 'moment';
import GoogleMapReact from 'google-map-react';

const { Title } = Typography
const format = "HH:mm";

const AnyReactComponent = ({ icon }) => (
    <div
        style={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)', // center the icon at the marker's position
            zIndex: 1, // ensure the icon appears on top of the marker
        }}
    >
        {icon}
    </div>
);

function BranchView({ windowWidth }) {

    let { id } = useParams();
    const isMobile = windowWidth <= 768

    const [branch, setBranch] = useState({})
    const [clickedLocation, setClickedLocation] = useState(null);

    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };

    const loadData = async (id) => {
        if (id > 0) {
            let response = await getBranch(id);
            if (response) {
                setBranch(response)
                setClickedLocation({ lat: response.latitude, lng: response.longitude })
            }
        }
    }

    useEffect(() => {
        loadData(id);
    }, []);

    const renderBranchForm = () => {
        return (
            <>
                <Card>
                    <Title level={2}>Branch Details</Title>
                    <Title level={3}>Basic Info :</Title>
                    <Row gutter={16} >
                        <Col span={4}>
                            <Title level={5}>Name</Title>
                        </Col>
                        <Col span={20}>
                            {branch.name}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>Email</Title>
                        </Col>
                        <Col span={20}>
                            {branch.email}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>Phone</Title>
                        </Col>
                        <Col span={20}>
                            {branch.phone}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>Currency</Title>
                        </Col>
                        <Col span={20}>
                            {branch.currency}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>Minimum Order Amount</Title>
                        </Col>
                        <Col span={20}>
                            {branch.min_order_amount}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>Opening Time</Title>
                        </Col>
                        <Col span={20}>
                            {moment(branch.opening_time, format).format(format) ?? null}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>Closing Time</Title>
                        </Col>
                        <Col span={20}>
                            {moment(branch.closing_time, format).format(format)}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>Cuisine</Title>
                        </Col>
                        <Col span={20}>
                            {branch.cuisine}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>Active</Title>
                        </Col>
                        <Col span={20}>
                            {branch.active === 1 ? 'Yes' : 'No'}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>Is Main</Title>
                        </Col>
                        <Col span={20}>
                            {branch.is_main === 1 ? 'Yes' : 'No'}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>Disclaimer</Title>
                        </Col>
                        <Col span={20}>
                            {branch.disclaimer}
                        </Col>
                    </Row>

                    <Divider />
                    <Title level={3}>Social :</Title>
                    <Row gutter={16} >
                        <Col span={4}>
                            <Title level={5}>Website</Title>
                        </Col>
                        <Col span={20}>
                            {branch.website}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>Facebook</Title>
                        </Col>
                        <Col span={20}>
                            {branch.facebook}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>Instagram</Title>
                        </Col>
                        <Col span={20}>
                            {branch.instagram}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>Snapchat</Title>
                        </Col>
                        <Col span={20}>
                            {branch.snapchat}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>Twitter</Title>
                        </Col>
                        <Col span={20}>
                            {branch.twitter}
                        </Col>
                    </Row>

                    <Divider />
                    <Title level={3}>Address :</Title>
                    <Row gutter={16} >
                        <Col span={4}>
                            <Title level={5}>Address</Title>
                        </Col>
                        <Col span={20}>
                            {branch.address}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>Zip Code</Title>
                        </Col>
                        <Col span={20}>
                            {branch.zipcode}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>City</Title>
                        </Col>
                        <Col span={20}>
                            {branch.city}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>Country</Title>
                        </Col>
                        <Col span={20}>
                            {branch.country}
                        </Col>
                        <Col span={4}>
                            <Title level={5}>Google Map</Title>
                        </Col>
                        <Col span={20}>
                            <div style={{ height: '50vh', width: '100%' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: "" }}
                                    defaultCenter={defaultProps.center}
                                    defaultZoom={defaultProps.zoom}
                                // onClick={handleMapClick}
                                >
                                    {clickedLocation && (
                                        <AnyReactComponent
                                            lat={clickedLocation.lat}
                                            lng={clickedLocation.lng}
                                            icon={<EnvironmentFilled style={{ color: '#6DAD00', fontSize: '24px' }} />}
                                            text="Clicked Location"
                                        />
                                    )}
                                </GoogleMapReact>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </>
        );
    }

    const renderBranchFormMobile = () => {
        return ('working');
    }

    const renderContent = () => {
        if (isMobile) {
            return renderBranchFormMobile()
        } else {
            return renderBranchForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )

};
export default windowSize(BranchView)